<app-header *ngIf="isAuthorized"></app-header>
<div class="app">
  <aside *ngIf="access | async" class="app__aside">
    <app-menu></app-menu>
    <div class="app__version">AutoBOP Admin v{{ version }}</div>
  </aside>
  <main class="app__main">
    <router-outlet></router-outlet>
  </main>
</div>
