<div class="notification">
  <mat-icon
    *ngIf="data && data.type"
    class="notification-icon"
    [ngClass]="{'notification-loading': data.type == 'sync'}"
  >
    {{ data.type }}
  </mat-icon>
  <div class="notification-caption" [innerHTML]="data?.caption"></div>
  <mat-icon class="notification-close" (click)="close()">
    highlight_off
  </mat-icon>
</div>
