import {Subject} from 'rxjs';
import {Injectable, ComponentRef} from '@angular/core';
import {DomAppendService} from './dom-append.service';
import {SidePanelComponent} from '../components/side-panel/side-panel.component';

export interface SidePanelOpenParams {
  row: any;
  config: any;
  clickedValue?: any;
  systemName?: string;
}

@Injectable()
export class SidePanelService {
  componentRef: ComponentRef<SidePanelComponent>;
  opened = false;
  length = 0;
  close$ = new Subject();

  constructor(private $dom: DomAppendService) {
    this.$dom.close$.subscribe((value: string) => {
      const id = +value.split('-').pop();
      this.length--;
      this.close$.next(id);
    });
  }

  open(params: SidePanelOpenParams, tableFieldId: number) {
    this.length++;
    this.componentRef = this.$dom.appendComponent(
      'sidepanel' + params.config.slug + '-' + tableFieldId,
      SidePanelComponent,
      Object.assign({}, params),
    );
  }
}
