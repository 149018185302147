import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatListModule} from '@angular/material/list';

import {
  ContractSchemeComponent,
  ObjectBottomSheetComponent,
} from './contract-scheme.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CustomSelectModule} from '@app/kit/custom-select';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    MatBottomSheetModule,
    MatListModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    CustomSelectModule,
  ],
  exports: [ContractSchemeComponent],
  declarations: [ContractSchemeComponent, ObjectBottomSheetComponent],
  providers: [],
})
export class ContractSchemeModule {}
