import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {TableColumnType} from '@shared/services/table.service';

@Component({
  selector: 'app-table-field-edit',
  templateUrl: './table-field-edit.component.html',
  styleUrls: ['./table-field-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFieldEditComponent implements OnInit {
  @Input() value: any;
  @Input() columnConfig: TableColumnType;
  @Input() apiPrefix = '/backoffice/v2';
  @Input() systemName = 'bop';
  @Input() selectFilterParams: any;
  @Input() row: any;

  @Output() onSelected = new EventEmitter();
  @Output() onDateSelected = new EventEmitter<Date>();
  @Output() onTextSelected = new EventEmitter<string>();
  @Output() onCheckSelected = new EventEmitter<boolean>();

  type:
    | 'string'
    | 'text'
    | 'int'
    | 'float'
    | 'boolean'
    | 'color'
    | 'date'
    | 'file'
    | 'action'
    | 'select'
    | 'fake'
    | 'json';

  constructor() {}

  ngOnInit() {
    this.type = this.columnConfig.type;
  }

  /**
   * Событие blur у выпадающего списка
   */
  updateSelect() {
    this.onSelected.emit(this.value);
  }

  /**
   * Событие blur у календаря
   */
  onDateBlur() {
    this.onDateSelected.emit(this.value);
  }

  /**
   * Событие blur у стандартных input и textarea
   */
  onBlur() {
    this.onTextSelected.emit(this.value);
  }

  /**
   * Событие у чекбоксов
   * @param {boolean} value
   */
  valueClick(value: boolean) {
    this.onCheckSelected.emit(value);
  }
}
