import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class CamelCaseInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('filter')) {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const camelCaseData = event.body.data.map(this.mapKeys);
          const modBody = event.body;
          modBody.data = camelCaseData;
          const modEvent = event.clone({
            body: modBody,
          });

          return modEvent;
        }
      }),
    );
  }

  private mapKeys(obj) {
    const result = {};
    Object.keys(obj).forEach(key => {
      result[key.replace(/^\w/, c => c.toLowerCase())] = obj[key];
    });
    return result;
  }
}
