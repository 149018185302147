import {
  AfterViewInit,
  Attribute,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {concat, Observable, of, Subject, throwError} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';

import {FetcherService} from '@core/services/fetcher.service';
import {NgSelectComponent} from '@ng-select/ng-select';
import {NotificationsService} from '@core/services/notifications.service';

export enum KEY_CODE {
  ENTER = 13,
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectComponent),
      multi: true,
    },
  ],
})
export class CustomSelectComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit
{
  @HostBinding('class.multiple') @Input() multiple = false;

  public get value() {
    return this._value;
  }

  public set value(v) {
    this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }

  private _value: any;
  isLoading = false;

  @Input() placeholder: string;
  @Input() required = false;
  @Input() inline = false;
  @Input() search: string[];
  @Input() startSearchAtChar = 3;
  @Input() sortBy: string;
  @Input() sticky = false;
  @Input() filter: any;
  @Input() items: any[] | string;
  @Input() columns: string[];
  @Input() extra: any[] | string;
  @Input() modelExtraFields: any;
  @Input() key = 'name';
  @Input() entityName = '';
  @Input() appendTo = null;
  @Input() idKey;
  @Input() itemsApiPrefix = '/backoffice/v2';
  @Input() itemsSystemName = 'bop';
  @Input() withEntity = false;
  @Input() dropdownPosition: 'bottom' | 'top' | 'auto';
  @Input() clearable = false;

  collection$: Observable<any[]>;
  input$ = new Subject<string>();

  @ViewChild('selectEl') input: NgSelectComponent;
  @Output() blur = new EventEmitter();
  @Output() change = new EventEmitter();

  constructor(
    @Attribute('class') classes: string,
    @Attribute('tabindex') tabIndex: string,
    @Attribute('autofocus') private autoFocus: any,
    @Attribute('disabled') public disabled: boolean,
    private _cd: ChangeDetectorRef,
    private $fetcher: FetcherService,
    private $notifications: NotificationsService,
  ) {}

  ngAfterViewInit() {
    if (this.inline) {
      this.input.focus();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filter || changes.entityName) {
      this.collection$ = this.initCollection();
    }
  }

  initCollection(): Observable<any[]> {
    if (this.withEntity && typeof this.entityName !== 'string') {
      return;
    }

    if (typeof this.items === 'string') {
      const params = {};

      if (this.sortBy) {
        params['sortType'] = true;
        params['sortBy'] = this.sortBy;
      }
      if (this.filter) {
        Object.keys(this.filter).forEach(key => {
          params[key] = this.filter[key];
        });
      }
      if (this.columns) {
        params['columns'] = this.columns.join(',');
      }

      const url = this.entityName ? `${this.items}/${this.entityName}` : this.items;
      if (this.search) {
        const initialArr = [];
        if (
          !this.required &&
          this.inline &&
          typeof this._value !== 'undefined' &&
          this._value !== null
        ) {
          initialArr.push({id: null, [this.key]: 'Очистить'});
        }
        return concat(
          of(initialArr),
          this.input$.pipe(
            debounceTime(200),
            filter((term: string) => term && term.length >= this.startSearchAtChar),
            distinctUntilChanged(),
            tap(() => (this.isLoading = true)),
            switchMap((term: string) => {
              return this.$fetcher
                .autoCompleteBySlug<any[]>(
                  url,
                  {
                    column: this.search.join(','),
                    value: [term],
                    ...params,
                  },
                  this.itemsApiPrefix,
                  this.itemsSystemName,
                )
                .pipe(
                  catchError(response => {
                    throwError(this.$notifications.showHttpError(response, 'GET', url));
                    return of([]);
                  }),
                );
            }),
            tap(() => (this.isLoading = false)),
          ),
        );
      } else {
        return this.$fetcher
          .getBySlug(
            url,
            {limit: 1000, ...params},
            this.itemsApiPrefix,
            this.itemsSystemName,
          )
          .pipe(
            map(resolve => {
              if (
                resolve.data &&
                resolve.data.length &&
                typeof resolve.data[0] === 'string'
              ) {
                resolve.data = resolve.data.map(name => ({name}));
              }
              return resolve.data;
            }),
            catchError(response => {
              throwError(this.$notifications.showHttpError(response, 'GET', url));
              return of([]);
            }),
          );
      }
    } else {
      return of(this.items);
    }
  }

  writeValue(value: string): void {
    this._value = value;
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.input) {
      this.input.setDisabledState(isDisabled);
      this._cd.markForCheck();
    }
  }

  onChangeValue(value) {
    if (!this.multiple) {
      this.onBlur();
    }
    this.change.emit(value);
  }

  onChange: any = v => {
    this.change.emit(v);
  };

  onTouched: any = () => {};

  onBlur() {
    this.input$.next();
    this.blur.emit();
  }

  clear() {
    this.value = '';
    this._cd.markForCheck();
  }
}
