import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {TableFileFieldType} from '@app/shared/services/table.service';
import {FetcherService} from '@core/services/fetcher.service';
import {catchError, finalize} from 'rxjs/operators';
import {EMPTY, throwError} from 'rxjs';
import {NotificationsService} from '@core/services/notifications.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @HostBinding('class.file-upload') mainClass = true;

  @Input() value: string;
  @Input() rowId: number;
  @Input() preview = false;
  @Input() previewLink: string;
  @Input() systemName = 'bop';
  @Input() config: TableFileFieldType;
  @Input() fileDropAccess: boolean;

  uploading: boolean;
  logoPDF = '/assets/img/logo-pdf.png';

  constructor(
    private $fetcher: FetcherService,
    private $notifications: NotificationsService,
  ) {}

  ngOnInit() {}

  onFileAdd(fileInput: HTMLInputElement) {
    if (fileInput.files.length > 0) {
      this.uploadFile(fileInput.files[0]);
    }
  }

  onFileDrop(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
    if (ev.dataTransfer && ev.dataTransfer.files.length > 0) {
      this.uploadFile(ev.dataTransfer.files[0]);
    }
  }

  private uploadFile(file: Blob) {
    this.uploading = true;
    const fd = new FormData();
    fd.append(this.config.fileKey || 'file', file);
    let url = this.config.endPoint.replace(':id', this.rowId.toString());
    if (this.config.key) {
      url = url.replace(':key', this.config.key);
    }
    this.$fetcher
      .postBySlug(url, fd, this.config.apiPrefix, this.config.system || this.systemName)
      .pipe(
        catchError(response => {
          throwError(this.$notifications.showHttpError(response, 'POST'));
          return EMPTY;
        }),
        finalize(() => {
          this.uploading = false;
        }),
      )
      .subscribe(({data, status}: any) => {
        if (status === 200) {
          this.value = data[this.config.prop];
          if (this.preview && this.previewLink) {
            this.previewLink = data[this.config.previewLink];
          }
        }
      });
  }

  remove() {}
}
