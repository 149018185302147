import {Injectable} from '@angular/core';
import {Resolve, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {RootTreeItem, NewMenuService} from '../services/menu.service';

@Injectable()
export class MenuResolver implements Resolve<RootTreeItem[]> {
  constructor(private $menu: NewMenuService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.$menu.getTree();
  }
}
