import {Component, HostBinding, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {trigger, transition, style, animate} from '@angular/animations';

interface SidePanelOptions {
  apiPrefix: string;
  slug: string;
  filter: string[];
  subTitleKey: string[];
}

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  animations: [
    trigger('initAnimation', [
      transition(':enter', [
        style({transform: 'translateX(100%)'}),
        animate('275ms', style({transform: 'translateX(0)'})),
      ]),
      transition(':leave', [
        style({transform: 'translateX(0)'}),
        animate('275ms', style({transform: 'translateX(100%)'})),
      ]),
    ]),
  ],
})
export class SidePanelComponent implements OnInit {
  @HostBinding('@initAnimation') public animation = true;

  slug: string;
  filter: any = {};
  subTitle: string;

  @Input() config: SidePanelOptions;
  @Input() row: any;
  @Input() readonly clickedParam: any;
  @Input() systemName: string;
  @Output() close$ = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.filter = {...this.clickedParam};
    this.subTitle = this.config.subTitleKey
      ? this.config.subTitleKey
          .map(k => this.row[k] || null)
          .filter(x => x)
          .join(', ')
      : null;
    if (this.config && this.config.filter) {
      this.config.filter.forEach(keys => {
        this.filter[keys[0]] = this.row[keys[1]];
      });
    }
  }

  close() {
    this.close$.emit();
  }
}
