import {Injectable} from '@angular/core';
import {NativeDateAdapter} from '@angular/material/core';
import {DateTime} from 'luxon';

@Injectable()
export class MatDateAdapter extends NativeDateAdapter {
  parse(input: any): Date | null {
    if (typeof input === 'string' && input.includes('.')) {
      const [day, month, year] = input.split('.');
      return DateTime.local(Number(year), Number(month), Number(day)).toJSDate();
    }
    const millis = typeof input === 'number' ? input : Date.parse(input);
    return isNaN(millis) ? null : DateTime.fromMillis(millis).toJSDate();
  }
}

@Injectable()
export class DateService {
  parseUtcDate(date?: string, format?: string, isLocal?: boolean): Date | string | null {
    if (!date) {
      return null;
    }
    const options = isLocal ? {} : {zone: 'utc'};
    const resDate = DateTime.fromISO(date, options);
    if (format) {
      return DateTime.fromMillis(resDate.toMillis()).toFormat(format);
    }
    return resDate.toJSDate();
  }

  parseJSDate(date?: Date, isLocal?: boolean): string | null {
    if (!date) {
      return null;
    }
    if (!this.isValidYear(date)) {
      date = this.fixJSDateYear(date);
    }
    if (isLocal) {
      return DateTime.fromJSDate(date).toISO({includeOffset: false});
    }
    return DateTime.fromJSDate(date).toUTC().toISO();
  }

  isValidJSDate(date?: Date, minDate?: Date, maxDate?: Date): boolean {
    if (!date) {
      return true;
    }
    const time = date.getTime();
    if ((minDate && time < minDate.getTime()) || (maxDate && time > maxDate.getTime())) {
      return false;
    }
    return true;
  }

  isValidYear(date?: Date): boolean {
    if (!date) {
      return true;
    }
    const dateTime = DateTime.fromJSDate(date);
    if (dateTime.year < 1900) {
      return false;
    }
    return true;
  }

  fixJSDateYear(date?: Date): Date {
    if (!date) {
      return date;
    }
    const dateTime = DateTime.fromJSDate(date);
    return dateTime
      .set({year: parseInt('20' + ('0' + String(dateTime.year)).slice(-2), 10)})
      .toJSDate();
  }
}
