import {share, pluck, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {Observable, of} from 'rxjs';

export interface ContractCashflowArticle {
  id: number;
  name: string;
}

@Injectable({providedIn: 'root'})
export class ContractCashflowArticleService {
  private contractCashflowArticles: Readonly<ContractCashflowArticle[]> | null = null;

  constructor(private readonly http: HttpClient) {}

  getContractCashflowArticles(): Observable<Readonly<ContractCashflowArticle[]>> {
    return this.contractCashflowArticles !== null
      ? of(this.contractCashflowArticles)
      : this.http
          .get<{data: Readonly<ContractCashflowArticle[]>}>(
            `${environment.api}/bank/backoffice/v1/contractChart/contractCashflowArticle`,
          )
          .pipe(
            pluck('data'),
            tap(articles => (this.contractCashflowArticles = articles)),
          );
  }
}
