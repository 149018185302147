import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DateTime} from 'luxon';
import {environment} from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  constructor(private readonly http: HttpClient) {}

  downloadExcel(url: string, fileName: string, options): Observable<ArrayBuffer> {
    let params = new HttpParams();

    Object.keys(options)
      .filter(key => options[key])
      .forEach(key => {
        params = params.append(key, options[key]);
      });

    return this.http
      .get(`${environment.api}/bop/${url}`, {
        responseType: 'arraybuffer',
        params,
      })
      .pipe(
        tap(response => {
          const blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });

          const blob_url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');

          anchor.target = '_blank';
          anchor.download = `${fileName} ${DateTime.local().toFormat('dd MM yyyy')}.xlsx`;
          anchor.href = blob_url;
          anchor.click();
          window.URL.revokeObjectURL(blob_url);
        }),
      );
  }
}
