import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';

import {CustomSelectComponent} from './custom-select.component';

@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule],
  exports: [CustomSelectComponent],
  declarations: [CustomSelectComponent],
  providers: [],
})
export class CustomSelectModule {}
