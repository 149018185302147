import {PikAuthConfig, PikEnvironment} from 'pik-header';

export const environment = {
  api: 'https://admin.testbop.pik-digital.ru',
  appName: 'bopadmin',
  assetsUrl: '',
  currentUser: null,
  hub: 'https://testbopadmin-api.pik.ru',
  level: 'test',
  needAuth: true,
  pikEnvironment: PikEnvironment.test,
  production: false,
  userCardsUrl: 'https://testhome.pik.ru/employees/',
  version: require('../../package.json').version,
  auth: {
    allowExternal: true,
    authEnvironment: PikEnvironment.test,
    clientId: 'backoffice_spa',
    showHeaderForExternal: true,
    scope:
      'openid profile email bop_api home_api pdl_api bank_api wiki_api photo_api partner_api sgp_api navigation_api allow_external plan_agreement_api plan_op_api',
  } as PikAuthConfig,
};
