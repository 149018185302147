import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'contract-scheme-dialog',
  templateUrl: './contract-scheme-dialog.template.html',
  styleUrls: ['./contract-scheme-dialog.style.scss'],
})
export class ContractSchemeDialogComponent {
  private _newObjectsValue;

  constructor(
    public dialogRef: MatDialogRef<ContractSchemeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public contract: any,
  ) {}

  onChanged(objects) {
    this._newObjectsValue = objects;
  }

  dismiss() {
    this.dialogRef.close(null);
  }

  save() {
    if (this._newObjectsValue) {
      this.dialogRef.close(this._newObjectsValue);
    } else {
      this.dismiss();
    }
  }
}
