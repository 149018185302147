import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {PikAuthService} from 'pik-header';

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
  constructor(private readonly authService: PikAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isFoundApiPath =
      !req.url.includes('/api/') && !req.url.includes('/backoffice/');

    if (isFoundApiPath) {
      return next.handle(req);
    }

    const authReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        this.authService.getAuthorizationHeaderValue(),
      ),
    });

    return next.handle(authReq);
  }
}
