import {Injectable} from '@angular/core';
import {environment} from '@env/environment';

@Injectable()
export class LocalStorageService {
  private readonly storage: Storage;

  constructor() {
    this.storage = window.localStorage;
  }

  get length(): number {
    return this.storage.length;
  }

  getItem(key: string): string | null {
    const version = this.storage.getItem('version');
    if (!version || version !== environment.version) {
      this.reset();
      this.storage.setItem('version', environment.version);
    }
    return this.storage.getItem(`config.${key}`);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(`config.${key}`, value);
  }

  private reset(): void {
    for (let index = 0; index < this.length; index++) {
      const key = this.key(index);
      if (key.startsWith('config')) {
        this.removeItem(key);
      }
    }
  }

  private removeItem(key: string): void {
    this.storage.removeItem(key);
  }
}
