import {AppComponent} from './app.component';
import ru from '@angular/common/locales/ru';
import {appRoutes} from 'app/app.routes';
import {BrowserModule} from '@angular/platform-browser';
import {CoreModule} from '@core/core.module';
import {HttpClientModule} from '@angular/common/http';
import {LOCALE_ID, NgModule} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'app/shared/shared.module';
import {PikHeaderModule} from 'pik-header';
import flatpickr from 'flatpickr';
import {Russian} from 'flatpickr/dist/l10n/ru.js';
import {MainComponent} from './main/main.component';
import {ContractSchemeModule} from './contract-scheme';

flatpickr.localize(Russian);
registerLocaleData(ru);

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'}),
    HttpClientModule,
    BrowserModule,
    SharedModule,
    CoreModule,
    ContractSchemeModule,
    PikHeaderModule,
  ],
  declarations: [AppComponent, MainComponent],
  providers: [{provide: LOCALE_ID, useValue: 'ru-RU'}],
  bootstrap: [AppComponent],
})
export class AppModule {}
