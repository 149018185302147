import {NgSelectModule} from '@ng-select/ng-select';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';

import {CovenantUploaderComponent} from './covenant-uploader.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatButtonModule,
    MatIconModule,
  ],
  declarations: [CovenantUploaderComponent],
})
export class CovenantUploaderModule {}
