<h1 class="error-code">{{ (data$ | async).code }}</h1>
<h3 class="error-title">{{ (data$ | async).title }}</h3>
<div class="error-message">
  {{ (data$ | async).message[0] }}
  <a
    *ngIf="(data$ | async).resolveLink"
    _target="blank"
    [href]="(data$ | async).resolveLink"
  >
    {{ (data$ | async).resolveLinkText }}
  </a>
  <ng-container *ngIf="(data$ | async).message[1]">
    {{ (data$ | async).message[1] }}
  </ng-container>
</div>
<div class="error-message">
  <div class="error-link">
    <a routerLink="/">Перейти на главную страницу</a>
  </div>
  <div class="error-link">
    Написать на почту поддержки
    <a
      href="mailto:0911@pik.ru?Subject={{ (data$ | async).mailSubject }}&amp;Body={{
        (data$ | async).mailBody
      }}"
    >
      0911@pik.ru
    </a>
  </div>
  <!-- <div class="error-link">Написать боту поддержки <a target="_blank" href="https://t.me/pik0911bot">@pik0911bot</a></div> -->
</div>
