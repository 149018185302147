<div class="manual-backdrop" (click)="dismiss()"></div>

<div class="dialog">
  <div class="dialog-title">
    <h4>Настройка колонок таблицы</h4>
  </div>
  <div class="dialog-content">
    <contract-scheme
      [objects]="contract.objects"
      [livingComplexId]="contract.livingComplexId"
      [livingComplexSchemeURL]="contract.livingComplexMainPlan"
      (change)="onChanged($event)"
    ></contract-scheme>
  </div>
  <div class="dialog-buttons">
    <button mat-raised-button class="dialog-button" (click)="dismiss()">
      Отменить
    </button>
    <button
      mat-raised-button
      color="primary"
      class="dialog-button"
      (click)="save()"
    >
      Сохранить
    </button>
  </div>
</div>
