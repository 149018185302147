import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {TableColumnType} from '@shared/services/table.service';
import {catchError, finalize, take, takeUntil} from 'rxjs/operators';
import {EMPTY, forkJoin, Subject, Subscription, throwError} from 'rxjs';
import {NotificationsService} from '@core/services/notifications.service';
import * as moment from 'moment';
import {FetcherService, Response} from '@core/services/fetcher.service';
import {SidePanelService} from '@shared/services/side-panel.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {ContractSchemeDialogComponent} from '../../contract-scheme-dialog/contract-scheme-dialog.component';
import {CovenantUploaderComponent} from '@app/covenant-uploader';

@Component({
  selector: 'app-table-field',
  templateUrl: './table-field.component.html',
  styleUrls: ['./table-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFieldComponent implements OnInit, OnChanges, OnDestroy {
  private putCallback: (res: any) => void;
  @Input() columnConfig: TableColumnType;
  @Input() row: any;
  @Input() value: any;
  @Input() slug: string;
  @Input() keyField = 'id';
  @Input() editRights: boolean;
  @Output() change = new EventEmitter();
  @Output() needUpdate = new EventEmitter();
  @Input() apiPrefix = '/backoffice/v2';
  @Input() systemName = 'bop';
  @Input() disableDeeper = false;
  @Input() colIndex: number;

  @ViewChild('calendarInput') calendarEl: any;

  _value: any;
  private _formattedValue: any;
  selectFilterParams: any;

  calendarObj: any;
  calendarOpen: boolean;
  dateFormats = [
    'YYYY-MM-DD',
    moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
    moment.HTML5_FMT.DATETIME_LOCAL_MS,
    'YYYY-MM-DDTHH:mm:ss.SSSSSS',
    'YYYY-MM-DDTHH:mm:ss.SS',
  ];
  visibleFormat = 'DD.MM.YY';

  editing: boolean;
  isLoading: boolean;
  activeSubscription: Subscription;
  tableFieldId = Math.round(Math.random() * 1000);

  wasEdited = false;

  get editable() {
    return (
      this.editRights &&
      !this.columnConfig.disabled &&
      this.columnConfig.tableEditing &&
      (!this.columnConfig.disabledUntil || this.row[this.columnConfig.disabledUntil]) &&
      (!this.columnConfig.disabledIfExists ||
        !this.row[this.columnConfig.disabledIfExists])
    );
  }

  get available() {
    if (!this.columnConfig.disabledUntil) {
      return true;
    }

    const disabledIfExists =
      !this.columnConfig.disabledIfExists ||
      !this.row[this.columnConfig.disabledIfExists];

    const disabledUntilValueEqual =
      this.columnConfig.disabledUntil &&
      this.row[this.columnConfig.disabledUntil] === this.columnConfig.disabledUntilValue;

    return (
      this.editRights &&
      !this.columnConfig.disabled &&
      disabledIfExists &&
      disabledUntilValueEqual
    );
  }

  get formattedValue() {
    return this._formattedValue;
  }

  set formattedValue(v) {
    if (v) {
      if (this.columnConfig.typeProps && this.columnConfig.typeProps.localTime) {
        this._formattedValue = moment
          .utc(v, this.dateFormats)
          .local()
          .format(this.visibleFormat);
      } else {
        this._formattedValue = moment(v, this.dateFormats).format(this.visibleFormat);
      }
    } else {
      this._formattedValue = null;
    }
  }

  get valid(): boolean {
    let valid = true;

    if (this.columnConfig.type === 'date') {
      valid = !this._value ? true : moment(this._value, this.dateFormats, true).isValid();
      return valid;
    }

    if (this.columnConfig.typeProps) {
      const props = this.columnConfig.typeProps;
      valid =
        valid && (props.regExp ? RegExp(props.regExp, 'i').test(this._value) : true);
    }
    valid =
      valid &&
      (!this.columnConfig.required ||
        (this.columnConfig.type === 'string'
          ? this._value.trim().length > 0
          : this._value !== null && typeof this._value !== 'undefined'));
    return valid;
  }

  jsonValue: string;

  private destroy$ = new Subject<boolean>();

  @HostBinding('class.clickable') get isClickable() {
    return this.columnConfig.clickLink || this.columnConfig.type === 'fake';
  }

  constructor(
    private $fetcher: FetcherService,
    private $notifications: NotificationsService,
    private $sidepanel: SidePanelService,
    private $cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private elRef: ElementRef,
  ) {}

  ngOnInit() {
    if (this.row.wasEdited && this.colIndex === 0) {
      this.scrollToRefEl();
    }

    if (this.row.wasEdited) {
      this.wasEdited = true;

      setTimeout(() => {
        this.wasEdited = false;
        this.$cd.markForCheck();
      }, 5000);
    }

    if (this.columnConfig.type === 'json') {
      this.jsonValue = JSON.stringify(this.value);
    }
    if (this.columnConfig.type === 'date') {
      this.formattedValue = this.value;
    }
    if (this.columnConfig.type === 'select') {
      if (this.value && Array.isArray(this.columnConfig.typeProps.items)) {
        const item = this.columnConfig.typeProps.items.find(x => x.id === this.value);
        this.row[this.columnConfig.typeProps.linkedKey] = item ? item.name : null;
      }
      if (this.columnConfig.typeProps.filter) {
        this.selectFilterParams = this.generateSelectFilterParams();
      }
    }
    if (this.columnConfig.requiredCondition) {
      this.columnConfig.required = this.getRequiredCompare(
        this.columnConfig.requiredCondition,
      );
    }
    this.onValueUpdates();

    this.$sidepanel.close$.pipe(takeUntil(this.destroy$)).subscribe(tableFieldId => {
      if (tableFieldId === this.tableFieldId) {
        this.needUpdate.emit();
      }
    });
  }

  ngOnChanges() {
    this.onValueUpdates();
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContractSchemeDialogComponent, {
      autoFocus: false,
      maxWidth: null,
      data: this.row,
      panelClass: ['scrollable-dialog', 'form-dialog'],
      disableClose: true,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((objects: any | null) => {
        if (objects !== null) {
          this.putCallback = res => {
            if (res) {
              forkJoin(
                objects
                  .filter(object => object.moved)
                  .map(object => {
                    return this.$fetcher.putBySlug(
                      'object',
                      object.objectId,
                      {
                        objectNumber: object.objectNumber,
                        mainPlanAbscissa: object.mainPlanAbscissa,
                        mainPlanOrdinate: object.mainPlanOrdinate,
                      },
                      '/backoffice/v1',
                      'partner',
                    );
                  }),
              )
                .pipe(
                  takeUntil(this.destroy$),
                  catchError(response => {
                    throwError(
                      this.$notifications.showHttpError(
                        response,
                        this.columnConfig.typeProps.method.toUpperCase(),
                      ),
                    );
                    return EMPTY;
                  }),
                )
                .subscribe((responses: any[]) => {
                  const updatedObjects = responses.map(({data}) => data);
                  this.row[this.columnConfig.prop] = this.value = this.value.map(
                    objectPoint => {
                      const newPoint = updatedObjects.find(
                        object => object.id === objectPoint.objectId,
                      );

                      if (newPoint) {
                        const newObjectPoint = {
                          objectNumber: newPoint.objectNumber,
                          mainPlanAbscissa: newPoint.mainPlanAbscissa,
                          mainPlanOrdinate: newPoint.mainPlanOrdinate,
                        };

                        return {
                          ...objectPoint,
                          ...newObjectPoint,
                        };
                      }

                      return objectPoint;
                    },
                  );
                });
            }
          };
          this.put(objects.map(object => object.objectId));
        }
      });
  }

  onBlur() {
    this.editing = false;

    if (this._value !== this.value && this.valid) {
      if (typeof this._value === 'string' && this._value.length === 0) {
        this._value = null;
      }

      this.put(this._value);
    } else {
      this._value = this.value;
    }
  }

  onTextBlur(value) {
    this._value = value;
    this.onBlur();
  }

  onDateBlur(value) {
    this._value = this.getDate(value);
    this.onBlur();
    this.formattedValue = this._value;
  }

  valueClick(value?) {
    if (this.columnConfig.clickLink) {
      window.open(this.row[this.columnConfig.clickLink], '_target');
    } else if (this.editable) {
      if (typeof value !== 'undefined') {
        this._value = value;
        this.onBlur();
      } else {
        this.editing = true;
      }
    }
  }

  openSidePanel(value?) {
    if (this.columnConfig.sidePanel) {
      if (!this.available) {
        this.$notifications.showErrorMessage(
          'Выберите в ячейке "Тип поля" значение "Выпадающий список"',
        );
        return;
      }
      const config = this.columnConfig.sidePanel;
      const sidePanelParams = {
        row: this.row,
        clickedParam: null,
        systemName: this.systemName || config.systemName,
        config,
      };

      if (config.filterByClickField) {
        sidePanelParams.clickedParam = {[config.filterByClickField]: value};
      }

      this.$sidepanel.open(sidePanelParams, this.tableFieldId);
    }
  }

  updateSelect(selectedValue) {
    this.editing = false;
    const idKey = this.columnConfig.typeProps.linkedIdKey || 'id';
    if (this.columnConfig.typeProps.multiple) {
      if (
        selectedValue
          .map(x => x.id)
          .sort()
          .join() !==
        this.value
          .map(x => x[idKey])
          .sort()
          .join()
      ) {
        this.put(selectedValue.map(x => x.id));
      }
    } else if (typeof selectedValue !== 'undefined' && selectedValue) {
      if (typeof selectedValue === 'string' && selectedValue !== this.value) {
        this.put(selectedValue);
      } else if (selectedValue[idKey] !== this.value) {
        this.put(selectedValue[idKey]);
      }
    } else if (!selectedValue && !this.columnConfig.required) {
      this.put(null);
    } else {
      this.onValueUpdates();
    }
  }

  removeItem(index: number) {
    if (this.editable) {
      this._value.splice(index, 1);
      this.put(this._value.map(x => x.id));
    }
  }

  openCoordinates() {
    this.openDialog();
  }

  openCovenantUploader() {
    const dialogRef = this.dialog.open(CovenantUploaderComponent, {
      autoFocus: false,
      maxWidth: null,
      data: {row: this.row, systemName: this.systemName},
      panelClass: ['default-dialog'],
    });

    dialogRef
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(() => {});
  }

  action() {
    this.isLoading = true;
    if (this.activeSubscription) {
      this.activeSubscription.unsubscribe();
    }
    const props = this.columnConfig.typeProps;
    const data = props.data || {};
    if (props.fields && props.fields.length > 0) {
      props.fields.forEach(field => {
        data[field] = this.row[field];
      });
    }
    const url =
      (props.apiPrefix || this.apiPrefix) +
      (props.key ? props.endPoint.replace(':id', this.row[props.key]) : props.endPoint);
    let activeObservable;
    if (['post', 'put'].includes(props.method)) {
      activeObservable = this.$fetcher[this.columnConfig.typeProps.method](
        url,
        data,
        props.systemName || this.systemName,
      );
    } else {
      activeObservable = this.$fetcher[this.columnConfig.typeProps.method](
        url,
        props.systemName || this.systemName,
      );
    }

    this.activeSubscription = activeObservable
      .pipe(
        takeUntil(this.destroy$),
        catchError(response => {
          throwError(
            this.$notifications.showHttpError(
              response,
              this.columnConfig.typeProps.method.toUpperCase(),
            ),
          );
          return EMPTY;
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe(res => {
        if (
          res &&
          res.data &&
          typeof res.data === 'string' &&
          res.data.includes('https://')
        ) {
          window.open(res.data, '_blank');
        } else {
          this.$notifications.showMessage('Обновлено', 'done', 1500);
        }
      });
  }

  private getRequiredCompare({fieldName, compare, target}): boolean {
    const compareFn = {
      gt: (a, b) => a > b,
      lt: (a, b) => a < b,
      eq: (a, b) => a === b,
    };

    const targetValue = typeof target === 'number' ? target : this.row[target];
    return compareFn[compare](this.row[fieldName], targetValue);
  }

  private put(value) {
    this.isLoading = true;
    const _value = ['float'].includes(this.columnConfig.type) ? value * 1 : value;
    const params = {[this.columnConfig.prop]: _value};
    const typeProps = this.columnConfig.typeProps;
    if (typeProps && typeProps.clearOnChange) {
      typeProps.clearOnChange.forEach(key => {
        if (
          this.row[key] !== null &&
          (!Array.isArray(this.row[key]) || this.row[key].length > 0)
        ) {
          params[key] = null;
        }
      });
    }
    this.activeSubscription = this.$fetcher
      .putBySlug<any>(
        this.slug,
        this.row[this.keyField],
        params,
        this.apiPrefix,
        this.systemName,
      )
      .pipe(
        takeUntil(this.destroy$),
        catchError((response: HttpErrorResponse) => {
          throwError(this.$notifications.showHttpError(response, 'PUT'));
          return EMPTY;
        }),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe((res: Response<any>) => {
        if (res) {
          this.row[this.columnConfig.prop] = this.value =
            res.data[this.columnConfig.prop];
          if (this.columnConfig.type === 'select' && !typeProps.multiple) {
            // Обновление полей
            if (typeProps.updateOnChange) {
              typeProps.updateOnChange.forEach((fields: string[]) => {
                this.row[fields[0]] = res.data[fields[0]];
              });
            }
            if (typeProps.clearOnChange) {
              typeProps.clearOnChange.forEach(key => {
                this.row[key] = res.data[key];
              });
            }
            const nameKey = typeProps.linkedKey;
            this.row[nameKey] = res.data[nameKey];
          }
          this.$cd.markForCheck();
          this.onValueUpdates();

          if (this.putCallback) {
            this.putCallback(res);
            this.putCallback = null;
          }
        }
      });
  }

  private getDate(value: string): string {
    const defaultDate = this.row[this.columnConfig.prop];
    const [start, end] = ['dateStart', 'dateEnd'];
    const dateFromValue = this.getSimpleDate(value);

    const isMinDate =
      this.columnConfig.typeProps?.minDateKey === start &&
      dateFromValue < this.getSimpleDate(this.row[start]);

    const isMaxDate =
      this.columnConfig.typeProps?.maxDateKey === end &&
      dateFromValue > this.getSimpleDate(this.row[end]);

    return isMinDate || isMaxDate ? defaultDate : value;
  }

  private getSimpleDate(value: string): string {
    return moment.utc(value).format('YYYY-MM-DD');
  }

  private onValueUpdates() {
    if (this.columnConfig.type === 'select') {
      const idKey = this.columnConfig.typeProps.linkedIdKey || 'id';
      const nameKey = this.columnConfig.typeProps.linkedKey || 'name';
      if (this.columnConfig.typeProps.multiple) {
        this._value = this.value.map(x => ({
          id: x[idKey],
          [this.columnConfig.typeProps.key]: x[nameKey],
        }));
      } else {
        if (this.value && Array.isArray(this.columnConfig.typeProps.items)) {
          const item = this.columnConfig.typeProps.items.find(x => x.id === this.value);
          this.row[nameKey] = item ? item.name : null;
        }
        this._value = {
          id: this.value,
          [this.columnConfig.typeProps.key]: this.row[nameKey],
        };
      }
      if (this.columnConfig.typeProps.filter) {
        this.selectFilterParams = this.generateSelectFilterParams();
      }
    } else {
      this._value = this.value;
    }
    if (this.columnConfig.type === 'date') {
      this.formattedValue = this.value;
    }
  }

  private generateSelectFilterParams() {
    const filter = this.columnConfig.typeProps.filter;
    if (!filter) {
      return null;
    }
    if (typeof filter === 'object') {
      return filter;
    } else {
      if (this.row[filter]) {
        if (this.columnConfig.typeProps.search) {
          return {
            linkTable: filter.replace('Id', ''),
            linkTableId: this.row[filter],
          };
        } else {
          return {
            [filter]: this.row[filter],
          };
        }
      } else {
        return {};
      }
    }
  }

  private scrollToRefEl() {
    setTimeout(() => {
      this.elRef.nativeElement.scrollIntoView({inline: 'nearest', block: 'center'});
    }, 250);
  }

  ngOnDestroy() {
    if (this.activeSubscription) {
      this.activeSubscription.unsubscribe();
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
