import {Component, OnInit} from '@angular/core';
import {catchError, filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {EMPTY, Observable, Subscription} from 'rxjs';

import {PikAuthService} from 'pik-header';
import {NgSelectConfig} from '@ng-select/ng-select';
import {User, UserService} from '@core/services/user.service';

import {environment} from '@env/environment';
import {NewMenuService} from '@core/services/menu.service';
import {LongTasksHub} from '@core/hubs/long-tasks.hub';
import {YandexMetricService} from './core/services/yandex-metric.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUser: User;
  access: Observable<boolean>;
  isAuthorized: boolean;
  version: string;

  sidePanelOpen: boolean;

  currentLocation: string;
  routerListener: Subscription;

  constructor(
    public $auth: PikAuthService,
    private $user: UserService,
    private selectConfig: NgSelectConfig,
    private $router: Router,
    private $menu: NewMenuService,
    private $longTasksHub: LongTasksHub, // private $notifications: NotificationsService,
    private readonly $yandexMetric: YandexMetricService,
  ) {
    this.$auth.authCallbackIfRequired();
    this.selectConfig.notFoundText = 'Ничего не найдено';
    this.selectConfig.loadingText = 'Загрузка...';
    this.selectConfig.typeToSearchText = 'Начните печатать для поиска';
    this.currentLocation = location.host;
    this.version = environment.version;
  }

  ngOnInit() {
    // const snack = this.$notifications.showLoader('Завершаем авторизацию');
    this.$auth.isAuthenticated$.subscribe(isAuthorized => {
      this.isAuthorized = isAuthorized;
      if (isAuthorized) {
        this.$user
          .getCurrent()
          .pipe(
            catchError(() => {
              this.$router.navigate(['error-user']);
              return EMPTY;
            }),
          )
          .subscribe(user => {
            this.access = this.$menu.hasAccess();
            if (!user) {
              this.$router.navigate(['error-access']);
            } else {
              this.currentUser = user;
              this.$longTasksHub.init();
              this.addYandexMetric();
              this.sbsRouter(user);
            }
            // snack.dismiss();
          });
      } else {
        console.log('Неавторизованный пользователь. Перенаправление...');
      }
    });
  }

  private addYandexMetric() {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = this.$yandexMetric.getYandexMetricScript();

    document.head.appendChild(script);
  }

  private checkYaMetrics(event: NavigationEnd, user: User): void {
    this.$yandexMetric.sendBrowsingData(event);
    this.$yandexMetric.sendUserData(user);
  }

  private sbsRouter(user: User): void {
    this.$router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => this.checkYaMetrics(event as NavigationEnd, user));
  }
}
