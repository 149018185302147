// import {publishReplay} from 'rxjs/operators/publishReplay';
import {map, share} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

import {Injectable} from '@angular/core';
import {FetcherService, Response} from './fetcher.service';

export interface User {
  adm_RoleId: number;
  admin: boolean;
  email: string;
  fullName: string;
  globalId: number;
  id: number;
  isNotDeleted: boolean;
  isRenovation: boolean;
  isRestricted: boolean;
  linkToUserCard: string;
  login: string;
  mds_EmployeeId: number;
  owner: boolean;
  photoUrl: string;
  reports: any[];
  rights: any[];
  userExists: boolean;
  userName: string;
}

@Injectable()
export class UserService {
  current: User;
  activeCurrentRequest: Observable<User>;

  constructor(private $fetcher: FetcherService) {}

  getCurrent(): Observable<User> {
    if (this.current) {
      return of(this.current);
    }
    if (this.activeCurrentRequest) {
      return this.activeCurrentRequest;
    }
    return (this.activeCurrentRequest = this.$fetcher
      .get<Response<User>>('/api/v2/user')
      .pipe(
        map(response => response.data),
        share(),
      ));
  }

  // getUserRights(): Observable<Response<{}>> {
  //   return this.fetcher.get('/backoffice/v2/userright').pipe(publishLast(), refCount());
  // }

  // getCurrentLogin(): Observable<string> {
  //   return this.getCurrent().pipe(map(
  //     res => res ? res.data.login : null,
  //     error => console.error(error)
  //   ))
  // }

  // getCurrentUser(): Promise<any> {
  //   return this.getCurrent().pipe(
  //     map(
  //       res => res ? res.data : {},
  //       error => console.error(error)
  //     )
  //   ).toPromise();
  // }

  // uploadImage(id, image) {
  //   if (image) {
  //     return this.fetcher.post('/backoffice/v2/user/uploadUserPhoto/' + id, image);
  //   }
  // }
}
