import {Injectable} from '@angular/core';
import {NavigationEnd} from '@angular/router';
import {User} from './user.service';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

declare let ym: any;

const YANDEX_METRIC_ID = 96083104;

@Injectable({
  providedIn: 'root',
})
export class YandexMetricService {
  reachGoal = new Subject<string>();

  constructor() {
    this.sbsReachGoal();
  }

  sendBrowsingData(event: NavigationEnd): void {
    ym(YANDEX_METRIC_ID, 'hit', event.urlAfterRedirects);
  }

  sendUserData(user: User): void {
    ym(YANDEX_METRIC_ID, 'userParams', {
      Name: user.fullName,
      Email: user.email,
    });
  }

  getYandexMetricScript(): string {
    return `
       (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
       m[i].l=1*new Date();
       for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
       k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
       (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
    
       ym(${YANDEX_METRIC_ID}, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true
       })`;
  }

  private onReachGoal(name: string): void {
    ym(YANDEX_METRIC_ID, 'reachGoal', name);
  }

  private sbsReachGoal(): void {
    this.reachGoal
      .pipe(debounceTime(300))
      .subscribe(trackOn => this.onReachGoal(trackOn));
  }
}
