<div *ngIf="multiple && inline" class="selected">
  <span *ngFor="let item of value" class="selected__item">{{ item[key] }}</span>
</div>
<ng-select
  #selectEl
  class="app-custom-select"
  [ngClass]="{
    'app-custom-select--inline': inline,
    'app-custom-select--required': required
  }"
  [appendTo]="inline && sticky ? '.datatable-scroll' : appendTo"
  [items]="collection$ | async"
  [searchable]="!!search"
  [isOpen]="inline ? true : null"
  [closeOnSelect]="!multiple"
  [bindLabel]="key"
  [multiple]="multiple"
  [clearable]="clearable || !required"
  [loading]="isLoading"
  [typeahead]="search ? input$ : null"
  [placeholder]="placeholder"
  [clearSearchOnAdd]="true"
  [required]="required"
  [dropdownPosition]="dropdownPosition || null"
  [(ngModel)]="value"
  (change)="onChangeValue($event)"
  (blur)="onBlur()"
  [disabled]="disabled"
>
  <ng-container *ngIf="!inline">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div
        *ngFor="let item of items | slice: 0:2"
        class="ng-value"
        (click)="!disabled && clear(item)"
      >
        <span class="ng-value-label">{{ item[key] }}</span>
        <span aria-hidden="true" class="ng-value-icon right">×</span>
      </div>
      <div *ngIf="items.length > 2" class="ng-value">
        <span class="ng-value-label">
          И ещё {{ items.length - 2 }}...&nbsp;
        </span>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="extra">
    <ng-template
      ng-option-tmp
      let-item="item"
      let-index="index"
      let-search="searchTerm"
    >
      <div class="ng-value-pre-extra">{{ item[key] }}</div>
      <div *ngFor="let option of extra" class="ng-value-extras">
        <div *ngIf="item[option.key]" class="ng-value-extra">
          {{ option.label ? option.label + ':&nbsp;' : '' }}{{ item[option.key]
          }}
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-select>
<div class="app-custom-select-hint">
  <ng-content></ng-content>
</div>
