<app-input
  placeholder="Поиск"
  class="menu-search"
  [formControl]="searchControl"
></app-input>

<div *ngFor="let rootItem of menu$ | async" class="menu-section">
  <h4 class="menu-header">{{ rootItem.name }}</h4>
  <ul *ngIf="isShowTree; else filteredMenu" class="menu-list">
    <ng-container *ngFor="let group of rootItem.tree">
      <li
        class="menu-list-item"
        [ngClass]="{'menu-list-item--opened': group.isOpen}"
      >
        <i
          *ngIf="group.items.length > 0"
          class="menu-list-item__arrow"
          (click)="group.isOpen = !group.isOpen"
        ></i>
        <a
          routerLink="/{{ rootItem.systemName }}/{{ group.section.code | lowercase }}"
          routerLinkActive="menu-list-link--active"
          class="menu-list-link menu-list-item__link"
          [ngClass]="{'menu-list-link--sub-active': group.section.isActive}"
        >
          {{ group.section.name }}
        </a>

        <ul
          *ngIf="group.items.length > 0 && group.isOpen"
          class="menu-list menu-list--sub"
        >
          <li *ngFor="let item of group.items" class="menu-list__item">
            <a
              routerLink="/{{ rootItem.systemName }}/{{ item.code | lowercase }}"
              routerLinkActive="menu-list-link--active"
              class="menu-list-link menu-list__link"
            >
              {{ item.name }}
            </a>
          </li>
        </ul>
      </li>
    </ng-container>
  </ul>

  <ng-template #filteredMenu>
    <ul class="menu-list menu-list--sub">
      <li *ngFor="let item of filteredMenuItems" class="menu-list__item">
        <a
          routerLink="/{{ rootItem.systemName }}/{{ item.code | lowercase }}"
          routerLinkActive="menu-list-link--active"
          class="menu-list-link menu-list__link"
        >
          {{ item.name }}
        </a>
      </li>
    </ul>
  </ng-template>
</div>
