import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import * as moment from 'moment';

const getSimpleDate = (value: string): string => {
  return moment.utc(value).format('YYYY-MM-DD');
};

const getDefaultValue = (
  formGroup: FormGroup,
  row: {[params: string]: string},
  type: string,
): string => {
  return formGroup ? formGroup.get(type).value : row[type];
};

export const maxDateValidator = (
  formGroup: FormGroup,
  row: {[params: string]: string},
  type: string,
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const defaultValue = getDefaultValue(formGroup, row, type);

    if (defaultValue && getSimpleDate(control.value) > getSimpleDate(defaultValue)) {
      return {maxDateKey: true};
    }

    return null;
  };
};

export const minDateValidator = (
  formGroup: FormGroup,
  row: {[params: string]: string},
  type: string,
): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const defaultValue = getDefaultValue(formGroup, row, type);

    if (defaultValue && getSimpleDate(control.value) < getSimpleDate(defaultValue)) {
      return {minDateKey: true};
    }

    return null;
  };
};
