import {
  AfterViewInit,
  Attribute,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import flatpickr from 'flatpickr';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Instance} from 'flatpickr/dist/types/instance';

const COMMON_FORMATS = [
  'D',
  'DM',
  'D.M',
  'D.M.YY',
  'D.M.YYYY',
  'DMYY',
  'DMYYYY',
  'YYYY-MM-DD',
];
enum KEY_CODE {
  ENTER = 13,
}

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    },
  ],
})
export class DateInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  isInvalidFormat = false;
  @HostBinding('class.app-input--inline') get inline() {
    return !this.placeholder;
  }
  @HostBinding('class.app-input--has-value') get hasValue() {
    return (this._value && this._value.length > 0) || this._formattedValue;
  }

  get value(): string {
    return this._value;
  }

  set value(v: string) {
    const date = this.localTime
      ? moment.utc(v, this.dateInFormats, true)
      : moment(v, this.dateInFormats, true);

    if (v) {
      this.dateValidation(date);
    } else {
      this._value = null;
      this.formattedValue = '';
    }

    if (this.calendar && this.calendar.isOpen) {
      this.calendar.setDate(this._value ? date.toDate() : null);
    }
    this.onChange(this._value);
    this.onTouched();
  }

  get formattedValue() {
    return this._formattedValue;
  }

  set formattedValue(v) {
    this._formattedValue = v;
  }
  @HostBinding('class.app-input') mainClass = true;
  @HostBinding('class.app-input--focused') focused = false;
  @HostBinding('class.app-input--disabled') disabled = false;

  calendar: Instance;

  @Input() placeholder: string;
  @Input() localTime: boolean;
  @Input() openOnFocus = false;
  @Input() dateInFormats = [
    'YYYY-MM-DD',
    moment.HTML5_FMT.DATETIME_LOCAL_SECONDS,
    moment.HTML5_FMT.DATETIME_LOCAL_MS,
    'YYYY-MM-DDTHH:mm:ss.SSSSSS',
    'YYYY-MM-DDTHH:mm:ss.SS',
  ];
  @Input() dateOutFormat = 'YYYY-MM-DD';
  @Input() visibleFormat = 'DD.MM.YY';
  @Input() clearable = true;
  @Input() required = false;
  @Output() change = new EventEmitter();
  @Output() blur = new EventEmitter();
  @ViewChild('inputEl') inputEl: ElementRef;
  @ViewChild('dateInput') dateInput: ElementRef;

  private _value: string;
  private _formattedValue: string;
  private isOpenCalendar = false;
  private readonly dateErrorMessage = 'Неверный формат';

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ENTER) {
      if (this.inputEl) {
        this.inputEl.nativeElement.blur();
      }
    }
  }

  constructor(
    @Attribute('class') public classes: string,
    @Attribute('tabindex') public tabIndex: string,
    @Attribute('autofocus') public autoFocus: any,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.focused = this.inline || this.autoFocus !== null;
  }

  ngAfterViewInit() {
    if (this.inline || this.autoFocus !== null) {
      if (this.inputEl) {
        this.inputEl.nativeElement.focus();
      }
    }
  }

  onDateChange(): void {
    this.blur.emit();
  }

  enableFlatpickr(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (!this.disabled) {
      this.calendar = flatpickr(this.dateInput.nativeElement, {
        dateFormat: 'd.m.y',
        static: true,
        clickOpens: false,
        onOpen: (selectedDates: any[], dateStr: string, instance: any) => {
          this.isOpenCalendar = true;

          if (this._value) {
            instance.setDate(
              (this.localTime
                ? moment.utc(this._value, this.dateInFormats, true).local()
                : moment(this._value, this.dateInFormats, true)
              ).toDate(),
            );
          }
        },

        onChange: (selectedDates, dateStr, instance) => {
          if (this.localTime) {
            this.value = moment.utc(selectedDates[0]).format('YYYY-MM-DDTHH:mm:ss');
          } else {
            this.value = moment(
              dateStr,
              [this.visibleFormat, ...COMMON_FORMATS],
              true,
            ).format(this.dateOutFormat);
          }

          this.cdr.detectChanges();
        },
        onClose: (selectedDates, dateStr, instance) => {
          this.isOpenCalendar = false;

          setTimeout(() => {
            instance.destroy();
            instance.element.blur();
            this.onInputBlur();
          }, 200);
        },
      });

      this.calendar.open();
      this.focused = true;
    }
  }

  writeValue(v: string): void {
    this.value = v;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onChange: any = v => {};

  onTouched: any = () => {};

  onInputFocus() {
    this.focused = true;

    if (this.isInvalidFormat) {
      this.isInvalidFormat = false;
      this.value = '';
    }

    if (this.openOnFocus) {
      this.enableFlatpickr();
    }
  }

  onInputBlur() {
    if (this.isOpenCalendar) {
      return;
    }

    this.inputEl.nativeElement.value = this.formattedValue;
    this.focused = false;
    this.blur.emit();
  }

  /**
   * Срабатывает только после окончания ручного ввода и событии blur
   * @param event
   */
  onInputChange(event) {
    if (this.isOpenCalendar) {
      return;
    }

    const value = event.target.value as string;
    if (!value.length) {
      return;
    }

    const date = moment(value, [this.visibleFormat, ...COMMON_FORMATS], true);

    if (!date.isValid() || (value.length < 8 && value.length)) {
      this.value = 'not valid';
      return;
    }

    if (this.localTime) {
      this.value = date.utc().format('YYYY-MM-DDTHH:mm:ss');
    } else {
      this.value = date.format(this.dateOutFormat);
    }
  }

  clear(event?: Event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.value = null;
    this.isInvalidFormat = false;
    this.cdr.markForCheck();
    this.blur.emit();
  }

  public validate(c: FormControl) {
    return null;
  }

  private dateValidation(date: Moment) {
    if (!date.isValid()) {
      this._value = this.dateErrorMessage;
      this.formattedValue = this.dateErrorMessage;
      this.isInvalidFormat = true;
      return;
    }

    this._value = date.format(
      this.localTime ? 'YYYY-MM-DDTHH:mm:ss' : this.dateOutFormat,
    );
    this.formattedValue = date.local().format(this.visibleFormat);
    this.isInvalidFormat = false;
  }
}
