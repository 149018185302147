<div class="side-panel-content">
  <span class="side-panel-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>
  <app-table
    [slug]="config.slug"
    [systemName]="systemName"
    [staticDataFilter]="filter"
    [subTitle]="subTitle"
    [disableDeeper]="false"
  ></app-table>
</div>
<div class="side-panel-shadow" (click)="close()"></div>
