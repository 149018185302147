import {Routes} from '@angular/router';
import {PikAuthGuard} from 'pik-header';

import {MainComponent} from './main/main.component';
import {MenuResolver} from './core/resolvers/menu.resolver';
import {ErrorPageComponent} from './core/error-page/error-page.component';
import {ContractSchemeComponent} from './contract-scheme';

export const appRoutes: Routes = [
  {
    path: 'coords',
    component: ContractSchemeComponent,
  },
  {
    path: '',
    resolve: {tree: MenuResolver},
    canActivate: [PikAuthGuard],
    children: [
      {
        path: 'error',
        component: ErrorPageComponent,
        data: {
          code: 404,
          title: 'Такой страницы не существует',
          message: 'Попробуйте перейти в другой справочник через боковое меню',
        },
      },
      {
        path: 'error-user',
        component: ErrorPageComponent,
        data: {
          code: 403.1,
          title: 'Ошибка при получении пользователя',
          message: 'Невозможно проверить права доступа. Обратитесь в тех. поддержку',
        },
      },
      {
        path: 'error-access',
        component: ErrorPageComponent,
        data: {
          code: 403.2,
          title: 'У вас нет доступа к этому приложению :(',
          message: [
            'Чтобы получить доступ, заполните',
            ' и отправьте её в службу поддержки.',
          ],
          resolveLink: 'https://service.pik.ru/?new=resource',
          resolveLinkText: 'служебную записку',
          mailSubject: 'Доступ к ресурсу',
          mailBody: location.origin,
        },
      },
      {
        path: ':systemName',
        children: [
          {
            path: 'undefined',
            component: ErrorPageComponent,
            data: {
              code: 404.1,
              title: 'Не найдена конфигурация',
              message: 'Для данного справочника ещё не создан файл конфигурации',
            },
          },
          {
            path: 'bad-config',
            component: ErrorPageComponent,
            data: {
              code: 404.2,
              title: 'Невалидный файл конфигурации',
              message: 'Не удалось обработать нужные параметры',
            },
          },
          {
            path: ':slug',
            component: MainComponent,
          },
          {
            path: '',
            redirectTo: '/bop/resolve',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: '',
        redirectTo: '/bop/resolve',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: '/error',
        pathMatch: 'full',
      },
    ],
  },
];
