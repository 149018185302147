import {CdkDragEnd} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
  MatBottomSheet,
  MatBottomSheetConfig,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import {Subject} from 'rxjs';

export interface ObjectPoint {
  objectId: number;
  objectName: string;
  objectNumber: string;
  mainPlanAbscissa: number;
  mainPlanOrdinate: number;
  moved?: boolean;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'object-bottom-sheet',
  template: `
    <div class="title">Редактировать объект</div>
    <div class="form" [formGroup]="formGroup">
      <app-custom-select
        *ngIf="livingComplexId"
        formControlName="object"
        placeholder="Объект"
        key="commercialName"
        items="object"
        sortBy="commercialName"
        itemsSystemName="partner"
        itemsApiPrefix="/backoffice/v1"
        idKey="id"
        dropdownPosition="top"
        class="form-field"
        [search]="['name', 'commercialName']"
        [startSearchAtChar]="1"
        [filter]="{
          linkTable: 'livingComplex',
          linkTableId: livingComplexId
        }"
        [required]="true"
      ></app-custom-select>
      <mat-form-field class="form-field">
        <mat-label>Номер</mat-label>
        <input matInput formControlName="objectNumber" autocomplete="off" />
      </mat-form-field>
    </div>
    <div class="actions">
      <button mat-flat-button (click)="dismiss($event)">Отмена</button>
      <button
        mat-flat-button
        color="primary"
        [disabled]="formGroup.invalid"
        (click)="save()"
      >
        Сохранить
      </button>
    </div>
  `,
  styles: [
    `
      .title {
        margin-bottom: 1em;
      }
      .form {
        margin-bottom: 1em;
      }
      .form-field {
        display: block;
      }
      .actions {
        display: flex;
        gap: 0.5em;
        justify-content: flex-end;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectBottomSheetComponent {
  readonly formGroup = new FormGroup({
    object: new FormControl(null, Validators.required),
    objectNumber: new FormControl(null, Validators.required),
  });

  livingComplexId: number;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ObjectBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private readonly data: {objectPoint: ObjectPoint; livingComplexId: number},
  ) {
    this.livingComplexId = this.data.livingComplexId;

    if (this.data.objectPoint) {
      const objectValue = {
        id: this.data.objectPoint.objectId,
        commercialName: this.data.objectPoint.objectName,
      };

      this.formGroup.setValue({
        object: objectValue,
        objectNumber: this.data.objectPoint.objectNumber,
      });
    }
  }

  save() {
    const object = this.formGroup.controls.object.value;
    const value = {
      objectId: object.id,
      objectName: object.commercialName,
      objectNumber: this.formGroup.controls.objectNumber.value,
    };
    this.bottomSheetRef.dismiss(value);
  }

  dismiss(event: MouseEvent) {
    event.preventDefault();
    this.bottomSheetRef.dismiss();
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'contract-scheme',
  templateUrl: './contract-scheme.template.html',
  styleUrls: ['./contract-scheme.style.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractSchemeComponent {
  @ViewChild('scheme') private readonly schemeElementRef: ElementRef;
  private zoom = 1;
  private readonly zoomStep = 0.1;
  private schemeDragging: boolean;
  private pointDragging: boolean;

  private _objects: ObjectPoint[] = [];
  selectedObject: ObjectPoint;
  shadowPoint: ObjectPoint;

  get objects(): ObjectPoint[] {
    return this._objects;
  }
  @Input() set objects(data: ObjectPoint[]) {
    this._objects = data;
    this.change.next(this._objects);
    this.cdr.detectChanges();
  }

  @Input() readonly livingComplexId: number;
  @Input() readonly livingComplexSchemeURL = '';
  @Output() readonly change = new Subject<ObjectPoint[] | null>();

  constructor(
    private readonly bottomSheet: MatBottomSheet,
    private cdr: ChangeDetectorRef,
  ) {}

  onPointMoved(event: CdkDragEnd, index: number) {
    const nativeElement = event.source.element.nativeElement;
    const [deltaX, deltaY] = nativeElement.style.transform
      .match(/(-?[.\d]+)px/g)
      .map(delta => parseInt(delta.replace('px', ''), 10));

    this.replaceObjectByIndex(index, {
      ...this.objects[index],
      mainPlanAbscissa: this.objects[index].mainPlanAbscissa + deltaX,
      mainPlanOrdinate: this.objects[index].mainPlanOrdinate + deltaY,
      moved: true,
    });
  }

  onSchemeDragStart() {
    this.schemeDragging = true;
  }

  onPointDragStart() {
    this.pointDragging = true;
  }

  onShemeMouseup(event): void {
    if (this.schemeDragging || this.pointDragging) {
      this.schemeDragging = false;
      this.pointDragging = false;
      return;
    }

    const {offsetX, offsetY} = event;

    this.shadowPoint = {
      objectId: null,
      objectName: null,
      objectNumber: '?',
      mainPlanAbscissa: offsetX - 20,
      mainPlanOrdinate: offsetY - 20,
      moved: true,
    };

    this.selectObject(null, null);
  }

  selectObject(object: ObjectPoint, index: number | null, event?: MouseEvent) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.pointDragging) {
      this.pointDragging = false;
      return;
    }

    const bottomSheetRef = this.bottomSheet.open(ObjectBottomSheetComponent, {
      panelClass: 'custom-mat-bottom-sheet',
      data: {
        objectPoint: object,
        livingComplexId: this.livingComplexId,
      },
    });

    bottomSheetRef.afterDismissed().subscribe(data => {
      if (index !== null && data) {
        this.replaceObjectByIndex(index, {
          ...this.objects[index],
          ...data,
        });
      } else {
        if (data) {
          this.addObjectPoint({
            ...this.shadowPoint,
            ...data,
          });
        }
        this.shadowPoint = null;
        this.cdr.detectChanges();
      }
    });
  }

  addObjectPoint(object) {
    this.objects = [...this.objects, object];
  }

  removeObjectPoint(index) {
    const objects = [...this.objects];

    objects.splice(index, 1);
    this.objects = objects;
  }

  zoomIn() {
    this.zoom += this.zoomStep;
    this.schemeElementRef.nativeElement.style.transform = `scale(${this.zoom})`;
  }

  zoomOut() {
    this.zoom -= this.zoomStep;
    this.schemeElementRef.nativeElement.style.transform = `scale(${this.zoom})`;
  }

  zoomReset() {
    this.zoom = 1;
    this.schemeElementRef.nativeElement.style.transform = `scale(${this.zoom})`;
  }

  private replaceObjectByIndex(index, object) {
    this.objects = [
      ...this.objects.slice(0, index),
      object,
      ...this.objects.slice(index + 1, this.objects.length),
    ];
  }
}
