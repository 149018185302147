import {Directive, HostListener} from '@angular/core';

@Directive({
  selector: 'input[type="text"][dateMask]',
})
export class DateMaskDirective {
  private value: string = '';
  private eventType: 'insert' | 'delete' | 'past' = 'insert';

  @HostListener('keydown', ['$event'])
  @HostListener('input', ['$event'])
  onKeyDown($event: KeyboardEvent | InputEvent) {
    const inputEl = $event.target as HTMLInputElement;

    if ($event instanceof KeyboardEvent) {
      if ($event.key === 'Backspace' || $event.key === 'Delete') {
        this.eventType = 'delete';
      } else {
        this.eventType = 'insert';
      }
    }

    if ($event instanceof InputEvent) {
      if (this.eventType === 'insert') {
        this.onInputChange(inputEl, $event.data);
      }

      if (this.eventType === 'delete') {
        this.onInputDelete(inputEl);
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste($event: ClipboardEvent): void {
    const pastedData = $event.clipboardData.getData('text');

    if (this.isValidPastedValue(pastedData)) {
      this.eventType = 'past';
    }
  }

  onInputDelete(inputEl: HTMLInputElement) {
    this.value = inputEl.value;
  }

  onInputChange(inputEl: HTMLInputElement, currentLater: string) {
    const isNumber = /[0-9]/g.test(currentLater);
    const valueNumbers = inputEl.value.replace(/\./g, '');
    const valueLength = valueNumbers.length;

    const day = valueNumbers.slice(0, 2);
    const month = valueNumbers.slice(2, 4);
    const shortYear = valueNumbers.slice(4, 6);
    const fullYear = valueNumbers.slice(4, 8);

    if (!isNumber) {
      inputEl.value = this.value;
      return;
    }

    if (valueLength > 8) {
      inputEl.value = `${day}.${month}.${fullYear}`;
      return;
    }

    if (valueLength === 2) {
      inputEl.value = `${day}.`;
    }

    if (valueLength > 2 && valueLength < 4) {
      inputEl.value = `${day}.${month}`;
    }

    if (valueLength === 4) {
      inputEl.value = `${day}.${month}.`;
    }

    if (valueLength > 4 && valueLength < 6) {
      inputEl.value = `${day}.${month}.${shortYear}`;
    }

    this.value = inputEl.value;
  }

  private isValidPastedValue(value: string): boolean {
    return /^[0-9\.]+$/.test(value);
  }
}
