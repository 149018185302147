<div
  class="app-input__container"
  [class.app-input__container--invalid]="isInvalidFormat"
>
  <label class="app-input__value">
    <div *ngIf="placeholder" class="app-input__placeholder">
      {{ placeholder }}
      <span *ngIf="required" class="app-input__required-star">*</span>
    </div>
    <div class="app-input__element">
      <input
        #inputEl
        type="text"
        dateMask
        [attr.tabindex]="tabIndex"
        [value]="formattedValue"
        [disabled]="disabled"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
        (change)="onInputChange($event)"
      />
    </div>
  </label>
  <span
    *ngIf="clearable && hasValue && !disabled && !required"
    title="Очистить"
    class="app-input__clear"
    (mousedown)="clear($event)"
  >
    <span aria-hidden="true" class="app-input__clear-icon">×</span>
  </span>
  <span
    class="app-input__suffix app-input-suffix--clickable"
    (mousedown)="enableFlatpickr($event)"
  >
    <mat-icon>today</mat-icon>
  </span>
</div>
<div *ngIf="!inline" class="app-input__hints">
  <ng-content></ng-content>
</div>
<input
  #dateInput
  type="text"
  tabindex="-1"
  class="app-input__date-hidden"
  (change)="onDateChange()"
  (blur)="onInputBlur()"
/>
