import {NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {AuthModule} from 'angular-auth-oidc-client';

import {throwIfAlreadyLoaded} from './module-import-guard';

import {MenuComponent} from './menu/menu.component';
import {ErrorPageComponent} from './error-page/error-page.component';

import {FetcherService} from './services/fetcher.service';
import {NewMenuService} from './services/menu.service';
import {UserService} from './services/user.service';
import {MenuResolver} from './resolvers/menu.resolver';

import {CamelCaseInterceptor} from './interceptors/camel-case.interceptor';
import {NotificationsService} from './services/notifications.service';
import {LocalStorageService} from './services/localStorage.service';
import {NotificationComponent} from './components/notification/notification.component';

import {LongTasksHub} from './hubs/long-tasks.hub';
import {SharedModule} from '@shared/shared.module';
import {ApplyTokenInterceptor} from '@core/interceptors/apply-token.interceptor';
import {
  GA_CONFIG,
  GAConfig,
  GoogleAnalyticsService,
  PikAuthModule,
  PikAuthService,
  PikCommonModule,
  PikErrorPagesModule,
  PikHeaderModule,
} from 'pik-header';
import {environment} from '@env/environment';

export function initGaFactory() {
  const gaConfig: GAConfig = {
    ga: null,
    optimize: null,
    googleTag: null,
  };

  return gaConfig;
}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    SharedModule,
    AuthModule.forRoot(),
    PikAuthModule.forRoot(environment.auth),
    PikCommonModule.forRoot({
      environment: environment.pikEnvironment,
    }),
    PikHeaderModule.forRoot(),
    PikErrorPagesModule,
  ],
  exports: [
    BrowserAnimationsModule,
    MenuComponent,
    ErrorPageComponent,
    NotificationComponent,
  ],
  declarations: [MenuComponent, ErrorPageComponent, NotificationComponent],
  providers: [
    FetcherService,
    NewMenuService,
    LocalStorageService,
    NotificationsService,
    LongTasksHub,
    UserService,
    MenuResolver,
    PikAuthService,
    GoogleAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApplyTokenInterceptor,
      multi: true,
    },
    {
      provide: GA_CONFIG,
      useFactory: initGaFactory,
      multi: false,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CamelCaseInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
