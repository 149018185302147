import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {RouterModule} from '@angular/router';
import {ColorPickerModule} from 'ngx-color-picker';
import {
  DateAdapter,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import {MaterialModule} from './material.module';

import {DateService, MatDateAdapter} from 'app/shared/services/date.service';
import {DomAppendService} from './services/dom-append.service';
import {SidePanelService} from './services/side-panel.service';
import {TableService} from './services/table.service';

import {ColorInputComponent} from './components/color-input/color-input.component';
import {DateInputComponent} from './components/date-input/date-input.component';
import {FileUploadComponent} from './components/file-upload/file-upload.component';
import {FormDialogComponent} from './components/form-dialog/form-dialog.component';
import {InputComponent} from './components/input/input.component';
import {SidePanelComponent} from './components/side-panel/side-panel.component';
import {TableComponent} from './components/table/table.component';
import {TableFieldComponent} from './components/table/table-field/table-field.component';
import {TableSearchComponent} from './components/table-search/table-search.component';
import {InputFilterDirective} from './directives/input-filter.directive';
import {DateMaskDirective} from '@shared/directives/date-mask.directive';
import {TextAreaComponent} from './components/text-area/text-area.component';
import {TableColumnsSettingsComponent} from './components/table-columns-settings/table-columns-settings.component';
import {TableAdvancedSearchComponent} from './components/table-advanced-search/table-advanced-search.component';
import {TableFieldEditComponent} from '@shared/components/table/table-field-edit/table-field-edit.component';
import {AddScrollToEmptyTableDirective} from './components/table/add-scroll-to-empty-table.directive';
import {ContractSchemeModule} from '@app/contract-scheme';
import {CustomSelectModule} from '@app/kit/custom-select';
import {ContractSchemeDialogComponent} from './components/contract-scheme-dialog/contract-scheme-dialog.component';
import {CovenantUploaderModule} from '@app/covenant-uploader';
import {YandexMetrikaGoalDirective} from './directives/yandex-metrika-goal.directive';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    ColorPickerModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    NgSelectModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    RouterModule,
    ContractSchemeModule,
    CovenantUploaderModule,
    CustomSelectModule,
  ],
  exports: [
    BrowserAnimationsModule,
    ColorInputComponent,
    DateInputComponent,
    FileUploadComponent,
    FormDialogComponent,
    TableColumnsSettingsComponent,
    TableAdvancedSearchComponent,
    FormsModule,
    InputComponent,
    MaterialModule,
    NgSelectModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    RouterModule,
    TableComponent,
    TableFieldComponent,
    TableSearchComponent,
    TextAreaComponent,
    TableFieldEditComponent,
    YandexMetrikaGoalDirective,
  ],
  declarations: [
    ColorInputComponent,
    DateInputComponent,
    FileUploadComponent,
    FormDialogComponent,
    TableColumnsSettingsComponent,
    TableAdvancedSearchComponent,
    InputComponent,
    InputFilterDirective,
    DateMaskDirective,
    SidePanelComponent,
    TableComponent,
    TableFieldComponent,
    TableSearchComponent,
    TextAreaComponent,
    TableFieldEditComponent,
    AddScrollToEmptyTableDirective,
    ContractSchemeDialogComponent,
    YandexMetrikaGoalDirective,
  ],
  providers: [
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    {provide: DateAdapter, useClass: MatDateAdapter},
    DateService,
    DomAppendService,
    SidePanelService,
    TableService,
  ],
})
export class SharedModule {}
