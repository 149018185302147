import {
  Injectable,
  Injector,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  ApplicationRef,
  ComponentRef,
} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class DomAppendService {
  private components = new Map<string, ComponentRef<any>>();
  private container = document.querySelector('.app__main');
  close$ = new Subject();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}

  appendComponent(name: string, component: any, instance?: any) {
    const componentRef: ComponentRef<any> = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);

    Object.keys(instance).forEach(key => {
      componentRef.instance[key] = instance[key];
    });

    componentRef.instance.close$.subscribe(value => {
      this.close$.next(name);
      this.removeComponent(name);
    });

    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    this.container.appendChild(domElem);
    this.components.set(name, componentRef);
    return componentRef;
  }

  removeComponent(name: string) {
    const componentRef = this.components.get(name);
    if (componentRef) {
      componentRef.destroy();
      setTimeout(() => {
        this.appRef.detachView(componentRef.hostView);
      }, 275);
    }
  }
}
