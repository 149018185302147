import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@env/environment';

import {PikAuthService} from 'pik-header';
import {map} from 'rxjs/operators';

export interface Response<T> {
  status: number;
  meta: {
    totalCount: number;
    limit: number;
    offset: number;
    userExists: boolean;
  };
  data: T;
  error: string;
}

export interface ErrorResponse {
  error: {
    data;
    error: string;
    meta;
    status: number;
    Status?: number;
    Error?: string;
  };
  message: string;
  name: string;
  ok: boolean;
  status: number;
  statusText: string;
  statusUrl: string;
}

@Injectable()
export class FetcherService {
  systemsEditingVerbs = new Map<string, string>();

  constructor(protected http: HttpClient, protected authService: PikAuthService) {}

  getApiUrl(url: string, serviceName: string): string {
    return environment.api + '/' + serviceName + url;
  }

  getBySlug(
    slug: string,
    paramsObject: any = {},
    apiPrefix: string,
    systemName = 'bop',
  ): Observable<Response<any[]>> {
    let params = new HttpParams();
    for (const key of Object.keys(paramsObject)) {
      if (paramsObject[key] != null) {
        params = params.append(key, paramsObject[key]);
      }
    }
    return this.http.get<Response<any[]>>(
      this.getApiUrl(`${apiPrefix}/${slug}`, systemName),
      {params},
    );
  }

  putBySlug<T>(
    slug: string,
    id: number,
    data: any,
    apiPrefix: string,
    systemName = 'bop',
  ): Observable<Response<T>> {
    let headers: HttpHeaders;
    const method = this.systemsEditingVerbs.get(systemName);
    if (method === 'patch') {
      headers = new HttpHeaders({'Content-Type': 'application/merge-patch+json'});
    }
    return this.http[method](
      this.getApiUrl(`${apiPrefix}/${slug}/${id}`, systemName),
      data,
      {headers},
    ); // <Response<T>>
  }

  autoCompleteBySlug<T>(
    slug: string,
    params,
    apiPrefix: string,
    systemName = 'bop',
  ): Observable<T> {
    return this.http
      .get<Response<T>>(this.getApiUrl(`${apiPrefix}/filter/${slug}`, systemName), {
        params,
      })
      .pipe(map(resolve => resolve.data));
  }

  postBySlug<T>(
    slug: string,
    data,
    apiPrefix: string,
    systemName = 'bop',
  ): Observable<Response<T>> {
    return this.http.post<Response<T>>(
      this.getApiUrl(`${apiPrefix}/${slug}`, systemName),
      data,
    );
  }

  deleteBySlug(
    slug: string,
    id: number,
    apiPrefix: string,
    systemName = 'bop',
  ): Observable<any> {
    return this.http.delete(this.getApiUrl(`${apiPrefix}/${slug}/${id}`, systemName));
  }

  // Старый код фетчера:

  get<T>(url: string, paramsObject: any = {}, serviceName = 'bop') {
    let params = new HttpParams();
    for (const key of Object.keys(paramsObject)) {
      if (paramsObject[key] != null) {
        params = params.append(key, paramsObject[key]);
      }
    }
    return this.http.get<T>(this.getApiUrl(url, serviceName), {params});
  }

  post<T>(url: string, data, serviceName = 'bop') {
    return this.http.post<T>(this.getApiUrl(url, serviceName), data);
  }

  put<T>(url: string, data, serviceName = 'bop') {
    if (url.startsWith('/bank/')) {
      const headers = new HttpHeaders({'Content-Type': 'application/merge-patch+json'});
      return this.http.patch<T>(this.getApiUrl(url, serviceName), data, {headers});
    }
    return this.http.put<T>(this.getApiUrl(url, serviceName), data);
  }

  delete<T>(url: string, serviceName = 'bop') {
    return this.http.delete<T>(this.getApiUrl(url, serviceName));
  }
}
