<div *ngIf="preview" class="file-upload__preview">
  <ng-container *ngIf="previewLink; else pdf">
    <img
      alt="config.label"
      class="file-upload__preview-image"
      [src]="previewLink"
    />
  </ng-container>

  <ng-template #pdf>
    <img
      class="file-upload__preview-image"
      [src]="logoPDF"
      [attr.alt]="config.label"
    />
  </ng-template>
</div>

<a href="{{ value }}" target="_blank" class="file-upload__current">
  {{ value }}
</a>

<div class="file-upload__control">
  <div class="file-upload__label">{{ config.label }}</div>
  <label
    *ngIf="!config.disabled"
    class="btn btn--blue file-upload__button"
    [ngClass]="{'btn--disabled': uploading}"
  >
    <mat-icon>attachment</mat-icon>
    &nbsp;
    <span>Загрузить файл</span>
    <input
      #fileUpload=""
      type="file"
      class="file-upload__input"
      [accept]="config.fileTypes"
      (change)="onFileAdd(fileUpload)"
    />
  </label>
</div>

<div
  *ngIf="!config.disabled && fileDropAccess"
  class="file-upload__drop-target"
  [ngClass]="{uploading: uploading}"
  (drop)="onFileDrop($event)"
>
  <mat-icon>insert_drive_file</mat-icon>
  <div class="file-upload__drop-label">Перетащите файлы сюда</div>
</div>
