<div class="manual-backdrop" (click)="onCancel()"></div>
<div class="dialog">
  <div class="dialog-title">
    <h4>Настройка колонок таблицы</h4>
  </div>
  <div class="dialog-actions">
    <span class="dialog-action as-link" (click)="toggleAll()">
      {{ checkAllPhrase }}
    </span>
  </div>
  <div class="dialog-content">
    <div class="checkbox-list">
      <ng-container *ngFor="let col of columnsConfig; let i = index">
        <div *ngIf="!col[3]" class="checkbox-list__item">
          <mat-checkbox [(ngModel)]="col[2]" (ngModelChange)="updateCheckAll()">
            {{ col[1] }}
          </mat-checkbox>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="dialog-buttons">
    <button mat-raised-button class="dialog-button" (click)="onCancel()">
      Отменить
    </button>
    <button
      ymGoal
      trackOn="trackAcceptSettingsOnTable"
      mat-raised-button
      color="primary"
      class="dialog-button"
      (click)="onSave()"
    >
      Сохранить
    </button>
  </div>
</div>
