<ng-container [ngSwitch]="type">
  <app-custom-select
    *ngSwitchCase="'select'"
    [inline]="true"
    [required]="columnConfig.required"
    [itemsSystemName]="columnConfig.typeProps.systemName || systemName"
    [itemsApiPrefix]="columnConfig.typeProps.apiPrefix || apiPrefix"
    [search]="columnConfig.typeProps.search"
    [multiple]="columnConfig.typeProps.multiple"
    [startSearchAtChar]="columnConfig.typeProps.startSearchAtChar || 3"
    [key]="columnConfig.typeProps.key"
    [extra]="columnConfig.typeProps.extra"
    [modelExtraFields]="columnConfig.typeProps.updateOnChange"
    [columns]="columnConfig.typeProps.columns"
    [items]="columnConfig.typeProps.items"
    [sortBy]="columnConfig.typeProps.sortBy"
    [filter]="selectFilterParams"
    [entityName]="
      columnConfig.typeProps.entityFilter
        ? row[columnConfig.typeProps.entityFilter]
        : null
    "
    [sticky]="columnConfig.sticky"
    [idKey]="columnConfig.typeProps.idKey"
    [(ngModel)]="value"
    (blur)="updateSelect()"
  ></app-custom-select>

  <app-date-input
    *ngSwitchCase="'date'"
    autofocus
    class="cell-input"
    [localTime]="!!columnConfig.typeProps && !!columnConfig.typeProps.localTime"
    [required]="columnConfig.required"
    [(ngModel)]="value"
    (blur)="onDateBlur()"
  ></app-date-input>

  <div
    *ngSwitchCase="'boolean'"
    class="cell-checkbox"
    [ngClass]="{
      'cell-checkbox--checked': value,
      'cell-checkbox--disabled': columnConfig.disabled || !columnConfig.tableEditing
    }"
    (click)="valueClick(!value)"
  >
    <mat-icon>checked</mat-icon>
  </div>

  <app-text-area
    *ngSwitchCase="'text'"
    autofocus
    class="cell-input"
    [appInputFilter]="columnConfig.typeProps?.charsFilter"
    [clearable]="!columnConfig.required"
    [required]="columnConfig.required"
    [(ngModel)]="value"
    (blur)="onBlur()"
  ></app-text-area>

  <app-input
    *ngSwitchDefault
    class="cell-input"
    [autoFocus]="true"
    [appInputFilter]="columnConfig.typeProps?.charsFilter"
    [type]="
      columnConfig.type == 'int' || columnConfig.type == 'float' ? 'number' : 'text'
    "
    [clearable]="!columnConfig.required"
    [required]="columnConfig.required"
    [(ngModel)]="value"
    (blur)="onBlur()"
  ></app-input>
</ng-container>
