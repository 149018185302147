<mat-form-field>
  <input
    matInput
    placeholder="Поиск"
    [formControl]="searchControl"
    (keyup.enter)="addChip($event)"
  />
</mat-form-field>

<div *ngIf="searchValues.length > 0" class="chips">
  <mat-chip-list class="chips__list">
    <mat-basic-chip
      *ngFor="let value of searchValues"
      class="chips__item"
      (removed)="removeChip(value)"
    >
      {{ value }}
      <mat-icon matChipRemove>close</mat-icon>
    </mat-basic-chip>
  </mat-chip-list>
</div>
