import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {LocalStorageService} from '@app/core/services/localStorage.service';
import {TableConfigType} from '@app/shared/services/table.service';

@Component({
  selector: 'app-table-columns-settings',
  templateUrl: 'table-columns-settings.component.html',
  styleUrls: ['table-columns-settings.component.scss'],
})
export class TableColumnsSettingsComponent implements OnInit {
  public columnsConfig: [string, string, boolean, boolean][];
  public checkAllPhrase = 'Выбрать все';

  constructor(
    private dialogRef: MatDialogRef<TableColumnsSettingsComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      config: TableConfigType;
      slug: string;
    },
    private $localStorage: LocalStorageService,
  ) {
    const configJSON = this.$localStorage.getItem(`${this.data.slug}Config`);
    if (configJSON) {
      try {
        this.columnsConfig = JSON.parse(configJSON);
      } catch {
        this.columnsConfig = this.createConfig();
      }
    } else {
      this.columnsConfig = this.createConfig();
    }
    this.updateCheckAll();
  }

  ngOnInit() {}

  public updateCheckAll(): void {
    this.checkAllPhrase =
      this.columnsConfig.length / 2 >
      this.columnsConfig.reduce((acc, cur) => acc + +cur[2], 0)
        ? 'Выбрать все'
        : 'Снять все';
  }

  public toggleAll() {
    if (
      this.columnsConfig.length / 2 >
      this.columnsConfig.reduce((acc, cur) => acc + +cur[2], 0)
    ) {
      this.columnsConfig.forEach(col => (col[2] = true));
      this.checkAllPhrase = 'Снять все';
    } else {
      this.columnsConfig.forEach(col => (col[2] = false));
      this.checkAllPhrase = 'Выбрать все';
    }
  }

  public onCancel() {
    this.dialogRef.close();
  }

  public onSave() {
    this.updateConfig();
    this.dialogRef.close(this.columnsConfig);
  }

  private createConfig(): [string, string, boolean, boolean][] {
    return (this.columnsConfig = this.data.config.columns.reduce((acc, col) => {
      acc.push([col.prop, col.label, true, !!col.hiddenInTable]);
      return acc;
    }, []));
  }

  private updateConfig(): void {
    this.$localStorage.setItem(
      `${this.data.slug}Config`,
      JSON.stringify(this.columnsConfig),
    );
  }
}
