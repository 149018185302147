<div class="app-input__container">
  <label class="app-input__value">
    <div *ngIf="placeholder" class="app-input__placeholder">
      {{ placeholder }}
      <span *ngIf="required" class="app-input__required-star">*</span>
    </div>
    <div class="app-input__element">
      <textarea
        #inputEl
        rows="3"
        [attr.tabindex]="tabIndex"
        [disabled]="disabled"
        [(ngModel)]="value"
        (focus)="onInputFocus()"
        (blur)="onInputBlur()"
      ></textarea>
    </div>
  </label>
  <span
    *ngIf="clearable && hasValue && !disabled"
    title="Очистить"
    class="app-input__clear"
    (mousedown)="clear()"
  >
    <span aria-hidden="true" class="app-input__clear-icon">×</span>
  </span>
</div>
<div *ngIf="!inline" class="app-input__hints">
  <ng-content></ng-content>
</div>
