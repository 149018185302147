<div #scheme class="scheme">
  <div
    cdkDrag
    class="scheme__pan"
    (mouseup)="onShemeMouseup($event)"
    (cdkDragEnded)="onSchemeDragStart()"
  >
    <img alt="" [src]="livingComplexSchemeURL" />
    <div
      *ngFor="let object of objects; let index = index"
      cdkDrag
      class="badge"
      [ngStyle]="{
        transform:
          'translate3d(' +
          object.mainPlanAbscissa +
          'px , ' +
          object.mainPlanOrdinate +
          'px, 0)'
      }"
      (cdkDragStarted)="onPointDragStart()"
      (cdkDragEnded)="onPointMoved($event, index)"
      (mouseup)="selectObject(object, index, $event)"
    >
      {{ object.objectNumber }}
    </div>

    <div
      *ngIf="shadowPoint"
      class="badge shadow-badge"
      [ngStyle]="{
        transform:
          'translate3d(' +
          shadowPoint.mainPlanAbscissa +
          'px , ' +
          shadowPoint.mainPlanOrdinate +
          'px, 0)'
      }"
      (mouseup)="selectObject(null, null, $event)"
    ></div>
  </div>
</div>
<!-- <div class="controls">
  <button (click)="zoomIn()">zoomIn</button>
  <button (click)="zoomOut()">zoomOut</button>
  <button (click)="zoomReset()">reset</button>
</div> -->
