import {
  Component,
  Input,
  Output,
  Attribute,
  forwardRef,
  ChangeDetectorRef,
  HostBinding,
  ViewChild,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  FormControl,
} from '@angular/forms';
import {take} from 'rxjs/operators';

export enum KEY_CODE {
  ENTER = 13,
}

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit
{
  @HostBinding('class.app-input--inline') get inline() {
    return !this.placeholder;
  }
  @HostBinding('class.app-input') mainClass = true;
  @HostBinding('class.app-input--has-value') get hasValue() {
    return (
      typeof this._value !== 'undefined' &&
      this._value !== null &&
      (this._value.length > 0 || this.value.toString().length > 0)
    );
  }
  @HostBinding('class.app-input--focused') focused = false;
  @HostBinding('class.app-input--disabled') disabled = false;

  public get value() {
    return this._value;
  }

  public set value(v) {
    this._value = v;
    this.onChange(this._value);
    this.onTouched();
  }
  private _value: string;
  focusOnInit = false;

  @Input() placeholder: string;
  @Input() clearable = true;
  @Input() required = false;
  @Input() type = 'text';

  @ViewChild('inputEl') input: ElementRef;
  @Output() blur = new EventEmitter();

  constructor(
    @Attribute('class') public classes: string,
    @Attribute('autofocus') private autoFocus: any,
    @Attribute('tabindex') public tabIndex: string,
    private _cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    if (this.inline || this.autoFocus !== null) {
      if (this.input) {
        this.input.nativeElement.focus();
      }
    }
  }

  ngAfterViewInit() {}

  writeValue(value: string): void {
    this._value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(c: FormControl) {
    return null;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cd.markForCheck();
  }

  onChange: any = () => {};

  onTouched: any = () => {};

  onInputFocus() {
    this.focused = true;
  }

  onInputBlur() {
    this.focused = false;
    this.blur.emit();
  }

  clear() {
    this.value = '';
    this._cd.markForCheck();
  }
}
