<div #test></div>

<ng-container *ngIf="config">
  <div class="table-header">
    <h4 class="table-header__title">{{ config.title }}</h4>
    <span class="table__flex-spacer"></span>
    <button
      *ngIf="selected.length > 0"
      mat-button
      color="primary"
      class="table-header__action"
      (click)="unselectAll()"
    >
      Снять выделение ({{ selected.length }})
    </button>
    <button
      *ngIf="config.delete && slugRights?.delete"
      ymGoal
      trackOn="trackRemoveOnTable"
      mat-raised-button
      color="warn"
      class="table-header__action"
      [disabled]="selected.length < 1 || disableEditing"
      (click)="deleteRows()"
    >
      Удалить
    </button>
    <button
      *ngIf="config.edit && slugRights?.edit"
      ymGoal
      trackOn="trackEditOnTable"
      mat-raised-button
      class="table-header__action"
      [disabled]="
        selected.length < 1 ||
        (!config.multipleEditing && selected.length > 1) ||
        disableEditing
      "
      (click)="openDialog(true)"
    >
      Редактировать
    </button>
    <button
      *ngIf="config.create && config.copy != false && slugRights?.create"
      ymGoal
      trackOn="trackCopyOnTable"
      mat-raised-button
      class="table-header__action"
      [disabled]="config.multipleCopying ? selected.length < 1 : selected.length != 1"
      (click)="openDialog(true, true)"
    >
      Копировать
    </button>
    <button
      *ngIf="config.create && slugRights?.create"
      ymGoal
      trackOn="trackAddOnTable"
      mat-raised-button
      color="primary"
      class="table-header__action"
      (click)="openDialog(false)"
    >
      Добавить
    </button>
  </div>
  <div class="table-subheader">
    <h6 *ngIf="subTitle" class="table-subheader__title">{{ subTitle }}</h6>
  </div>
  <div class="table-filters">
    <app-table-search
      class="table-filters__item"
      (onSearch)="onSearch($event)"
    ></app-table-search>
    <mat-form-field class="table-filters__item">
      <mat-select
        placeholder="Кол-во строк"
        [(ngModel)]="tableParams.limit"
        (selectionChange)="paramsChanged()"
      >
        <mat-option *ngFor="let count of pageSizeOptions" [value]="count">
          {{ count }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="config.isRenovation" class="table-filters__item">
      <mat-select
        placeholder="Реновация"
        [disabled]="disableUI"
        [(ngModel)]="tableParams.isRenovation"
        (selectionChange)="paramsChanged()"
      >
        <mat-option [value]="null">Все</mat-option>
        <mat-option [value]="true">Да</mat-option>
        <mat-option [value]="false">Нет</mat-option>
      </mat-select>
    </mat-form-field>
    <button
      ymGoal
      trackOn="trackOpenSettingOnTable"
      mat-icon-button
      class="table-filters__item mat-custom-icon-button"
      (click)="openTableColumnsSettings()"
    >
      <mat-icon>settings</mat-icon>
    </button>
    <button
      *ngIf="!!config.searchColumns"
      ymGoal
      trackOn="trackOpenFilterOnTable"
      mat-icon-button
      class="table-filters__item mat-custom-icon-button"
      [ngClass]="{'table-filters__item--active': !!searchParams}"
      (click)="openAdvancedSearchSettings()"
    >
      <mat-icon>filter_alt</mat-icon>
    </button>
    <span class="table__flex-spacer"></span>
    <mat-checkbox
      *ngIf="config.isRestricted"
      class="table-filters__item"
      [(ngModel)]="tableParams.restricted"
      (change)="paramsChanged()"
    >
      Только активные
    </mat-checkbox>
  </div>
  <div class="table-container">
    <ngx-datatable
      *ngIf="columnsConfig && hasColumns"
      #table
      class="material"
      [columnMode]="'force'"
      [rowHeight]="'auto'"
      [rows]="data"
      [count]="totalCount"
      [messages]="{emptyMessage: 'Записи не найдены'}"
      [reorderable]="false"
      [scrollbarV]="true"
      [scrollbarH]="true"
      [headerHeight]="38"
      [footerHeight]="80"
      [virtualization]="false"
      [loadingIndicator]="isLoadingResults"
      [externalSorting]="true"
      [externalPaging]="true"
      [limit]="tableParams.limit"
      [selectionType]="'checkbox'"
      [selected]="selected"
      (sort)="onSort($event)"
      (select)="onSelect($event)"
      (page)="onPageChange($event)"
    >
      <ngx-datatable-column
        *ngIf="(config.edit && slugRights?.edit) || (config.delete && slugRights?.delete)"
        [width]="36"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false"
        [frozenLeft]="true"
        [headerClass]="'mat-ripple-fix'"
        [cellClass]="'checkbox-cell'"
      >
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn"
        >
          <mat-checkbox
            [checked]="allRowsSelected"
            (change)="selectFn(!allRowsSelected)"
          ></mat-checkbox>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn"
          let-row="row"
        >
          <mat-checkbox
            [checked]="isSelected"
            (change)="onCheckboxChangeFn($event)"
          ></mat-checkbox>
        </ng-template>
      </ngx-datatable-column>

      <ng-container *ngFor="let column of columnsConfig; let i = index">
        <ngx-datatable-column
          *ngIf="
            column[2] && !config.columns[i].helper && !config.columns[i].hiddenInTable
          "
          [draggable]="false"
          [name]="column[1]"
          [prop]="column[0]"
          [width]="config.columns[i].width || 250"
          [minWidth]="
            (columnMinMaxWidthByType[config.columns[i].type] ||
              columnMinMaxWidthByType.default)[0]
          "
          [maxWidth]="
            (columnMinMaxWidthByType[config.columns[i].type] ||
              columnMinMaxWidthByType.default)[1]
          "
          [frozenLeft]="config.columns[i].sticky"
          [sortable]="!config.columns[i].sortDisabled"
        >
          <ng-template
            ngx-datatable-cell-template
            let-rowIndex="rowIndex"
            let-value="value"
            let-row="row"
            style="border: 1px solid #333"
          >
            <app-table-field
              [colIndex]="i"
              [columnConfig]="config.columns[i]"
              [editRights]="
                config.edit &&
                slugRights?.edit &&
                (!config.disableEditingOn || !row[config.disableEditingOn])
              "
              [row]="row"
              [value]="value"
              [slug]="redirectSlug || slug"
              [systemName]="systemName"
              [apiPrefix]="config.apiPrefix"
              [disableDeeper]="disableDeeper"
              (needUpdate)="paramsChanged()"
            ></app-table-field>
          </ng-template>
        </ngx-datatable-column>
      </ng-container>

      <ngx-datatable-column *ngIf="config.files" [minWidth]="250">
        <ng-template ngx-datatable-header-template>Файлы</ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          style="border: 1px solid #333"
        >
          <ng-container *ngFor="let file of config.files">
            <a
              *ngIf="row[file.prop]"
              target="_blank"
              class="table-cell--attachment-item"
              [href]="row[file.prop]"
            >
              <mat-icon>attachment</mat-icon>
              <span class="table-cell--attachment-label">{{ file.label }}</span>
            </a>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column *ngIf="config.icons" [minWidth]="250">
        <ng-template ngx-datatable-header-template>Файлы</ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-row="row"
          style="border: 1px solid #333"
        >
          <ng-container *ngFor="let icon of config.icons">
            <div *ngIf="row[icon.previewLink]" class="table-cell__icon">
              <img
                alt="config.label"
                class="table-cell__icon-image"
                [src]="row[icon.previewLink]"
              />
            </div>
          </ng-container>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-footer>
        <ng-template
          ngx-datatable-footer-template
          let-rowCount="rowCount"
          let-pageSize="pageSize"
          let-selectedCount="selectedCount"
          let-curPage="curPage"
          let-offset="offset"
        >
          <div style="padding: 5px 10px">
            <div>Всего: {{ rowCount }} | Выбрано: {{ selected.length }}</div>
            <div *ngIf="sheetsAccess | async">
              <mat-progress-spinner
                *ngIf="isLoadingSheets"
                mode="indeterminate"
                diameter="25"
              ></mat-progress-spinner>
              <span
                *ngIf="!isLoadingSheets"
                ymGoal
                trackOn="trackDownloadExcelOnTable"
                class="as-link"
                (click)="openGoogleSheets()"
              >
                {{config.isExportToFile ? 'Выгрузить в Excel' : 'Выгрузить в
                Google Таблицы'}}
              </span>
            </div>
          </div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="table.onFooterPage($event)"
          ></datatable-pager>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
  </div>
</ng-container>
