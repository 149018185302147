import {Component} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent {
  data$: Observable<{
    code: number;
    title: string;
    message: string[];
    resolveLink: string;
    resolveLinkText: string;
    mailSubject: string;
    mailBody: string;
  }>;

  constructor(private $route: ActivatedRoute) {
    this.data$ = this.$route.data.pipe(
      map(
        ({
          code,
          title,
          message,
          resolveLink,
          resolveLinkText,
          mailSubject,
          mailBody,
        }: Data) => ({
          code,
          title,
          message: typeof message === 'string' ? [message] : message,
          resolveLink,
          resolveLinkText,
          mailSubject: mailSubject || 'Ошибка в работе админки ' + code,
          mailBody: mailBody || title,
        }),
      ),
    );
  }
}
