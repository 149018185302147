import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {map, tap, catchError, retryWhen, switchMap} from 'rxjs/operators';

export interface TableConfigType {
  redirectSlugName?: string;
  title: string;
  keyField?: string;
  postAsArray?: boolean;
  isRestricted?: boolean;
  isRenovation?: boolean;
  isExportToFile?: boolean;
  multipleEditing: boolean;
  multipleCopying?: boolean;
  export?: boolean;
  create?: boolean;
  edit?: boolean;
  copy?: boolean;
  delete?: boolean;
  disableEditingOn?: string;
  isLongTask?: boolean;
  pageSize?: 10 | 25 | 50;
  sortBy?: string;
  columns: TableColumnType[];
  searchColumns?: TableColumnType[];
  oneFieldDefault?: string;
  apiPrefix?: string;
  systemName?: string;
  files?: TableFileFieldType[];
  icons?: any;
}

export interface TableColumnType {
  prop: string;
  type:
    | 'string'
    | 'text'
    | 'int'
    | 'float'
    | 'boolean'
    | 'color'
    | 'date'
    | 'file'
    | 'action'
    | 'select'
    | 'fake'
    | 'json';
  label: string;
  width?: number;
  minWidth?: number;
  required?: boolean;
  requiredCondition?: RequriedCondition;
  tableEditing?: boolean;
  multipleEditing?: boolean;
  disabled?: boolean;
  disabledEdit?: boolean;
  disabledUntil?: string; // Выключить поле, пока не заполнено другое
  disabledUntilValue?: string; // Выключить поле, пока не будет присвоено значение равное этому полю (работает совместно с disabledUntil)
  disabledIfExists?: string; // Выключить поле, если заполнено другое
  hidden?: boolean;
  helper?: boolean;
  hiddenUnless?: string;
  hiddenInTable?: boolean;
  sortDisabled?: boolean;
  sticky?: boolean;
  default?: any; // Дефолтное значение при создании записи
  tooltip?: boolean;
  sidePanel?: any;
  typeProps?: any;
  clickLink?: string;
}

interface RequriedCondition {
  fieldName: string;
  compare: 'gt' | 'lt' | 'eq';
  target: number | string;
}

export interface TableFileFieldType {
  label: string;
  prop: string;
  endPoint?: string;
  fileKey?: string;
  key?: string;
  preview?: boolean;
  previewLink?: string;
  system?: string;
  apiPrefix?: string;
  disabled?: boolean;
  fileTypes?: string[];
}

const defaultOnlyNameConf = (config): TableConfigType => {
  return {
    title: config.oneFieldDefault,
    systemName: config.systemName,
    apiPrefix: config.apiPrefix,
    redirectSlugName: config.redirectSlugName,
    multipleEditing: false,
    create: true,
    edit: true,
    delete: true,
    isRestricted: false,
    isRenovation: false,
    sortBy: 'name',
    isExportToFile: !!config.isExportToFile,
    columns: [
      {
        label: 'Название',
        prop: 'name',
        type: 'string',
        tableEditing: true,
        required: true,
      },
    ],
  };
};

@Injectable()
export class TableService {
  configs = new Map<string, TableConfigType>();

  constructor(private http: HttpClient) {}

  getConfig(slug: string, subDir?: string): Observable<TableConfigType> {
    const configHashName = subDir ? `${subDir}:${slug}` : slug;

    if (this.configs.has(configHashName)) {
      return of(this.configs.get(configHashName));
    }

    const noCacheHeaders = new HttpHeaders({'Cache-Control': 'no-cache'});
    const url = subDir
      ? `./assets/mock/${subDir}/${slug}.json`
      : `./assets/mock/${slug}.json`;

    return this.http.get<TableConfigType>(url, {headers: noCacheHeaders}).pipe(
      map(config => (config.oneFieldDefault ? defaultOnlyNameConf(config) : config)),
      tap(config => {
        this.configs.set(configHashName, config);
      }),
      catchError(errors => {
        if (!!subDir) {
          return this.getConfig(slug);
        }

        return throwError(errors);
      }),
    );
  }
}
