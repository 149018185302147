import {Component} from '@angular/core';
import {ActivatedRoute, Params, Router, Data} from '@angular/router';
import {NewMenuService} from '@app/core/services/menu.service';

@Component({
  selector: 'app-mat-table',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  slug: string;
  systemName: string;

  constructor(
    private $aRoute: ActivatedRoute,
    private $router: Router,
    private $menu: NewMenuService,
  ) {
    this.$aRoute.params.subscribe((params: Params) => {
      if (this.$menu.items.length === 0) {
        this.$router.navigate(['error-access']);
      } else {
        if (params.slug === 'resolve') {
          if (this.$menu.items.length > 0) {
            const resolvedRoute = this.$menu.items[0];
            this.$router.navigate([
              resolvedRoute.systemName,
              resolvedRoute.code.toLowerCase(),
            ]);
          }
        } else {
          this.slug = params.slug;
          this.systemName = params.systemName;
        }
      }
    });
  }
}
