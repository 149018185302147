import {Injectable} from '@angular/core';
import {NotificationsService} from '../services/notifications.service';
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import {environment} from '@env/environment';
import {PikAuthService} from 'pik-header';

const TASK_TYPES = {
  CreatePermissionByBusinessRoleMilestone: {
    fieldName: 'businessRoleMilestoneIds',
    errorText: 'Раздача прав для справочника Бизнес роли событий вернулась с ошибкой',
  },
  UpdatePermissionByBusinessRoleMilestone: {
    fieldName: 'businessRoleMilestoneId',
    errorText: '',
  },
  CreatePermissionByBusinessRoleUser: {
    fieldName: 'businessRoleUserIds',
    errorText: '',
  },
  UpdatePermissionByBusinessRoleUser: {
    fieldName: 'businessRoleUserId',
    errorText: '',
  },
  ObjectHousingRateIdSync: {
    fieldName: 'objectIds',
    errorText: '',
  },
  ExecutionDirectionIdBusinessSync: {
    fieldName: 'objectIds',
    errorText: '',
  },
};

@Injectable()
export class LongTasksHub {
  connection: HubConnection;

  constructor(
    private $notifications: NotificationsService,
    private $auth: PikAuthService,
  ) {}

  init() {
    if (this.connection) {
      return;
    }
    this.connection = new HubConnectionBuilder()
      // .configureLogging(LogLevel.Trace)
      .withUrl(environment.hub + '/hub/adminHub', {
        accessTokenFactory: () => this.$auth.getToken(),
        // transport: HttpTransportType.WebSockets || HttpTransportType.LongPolling
      })
      .build();
    this.connection.on('notifyUser', args => {
      const message = this.generateMessage(args);
      if (message) {
        if (args.error) {
          this.$notifications.showError(null, message, 5000);
        } else {
          this.$notifications.showMessage(message, 'done', 4000);
        }
      }
    });

    this.start();
  }

  private async start() {
    try {
      await this.connection.start();
      console.assert(this.connection.state === HubConnectionState.Connected);
      console.log('connected');
    } catch (err) {
      console.assert(this.connection.state === HubConnectionState.Disconnected);
      console.log(err);
      setTimeout(() => this.start(), 5000);
    }
  }

  private generateMessage({taskType, data, error}): string {
    let target: string;
    let result: string;
    if (!data) {
      return '';
    }
    switch (taskType) {
      case 'CreatePermissionByBusinessRoleUser':
      case 'UpdatePermissionByBusinessRoleUser': {
        const userNames =
          (data['userName'] ? [data['userName']] : data['userNames']) || [];
        target =
          'Для ' +
          (userNames && userNames.length > 1 ? 'пользователей ' : 'пользователя ') +
          userNames.join(', ');
        result = error
          ? ` при раздаче прав произошла ошибка. ${error}`
          : `раздача прав прошла успешно`;
        break;
      }

      case 'CreatePermissionByBusinessRoleMilestone':
      case 'UpdatePermissionByBusinessRoleMilestone': {
        const milestoneNames =
          (data['milestoneName'] ? [data['milestoneName']] : data['milestoneNames']) ||
          [];
        target =
          'По ' +
          (milestoneNames && milestoneNames.length > 1 ? 'событиям ' : 'событию ') +
          milestoneNames.join(', ');
        result = error
          ? ` при раздаче прав произошла ошибка. ${error}`
          : `раздача прав прошла успешно`;
        break;
      }

      case 'ObjectHousingRateIdSync': {
        const objectIds = data.objectIds || [];
        let objectsCount = '';
        if (objectIds.length > 0) {
          objectsCount =
            'Произошли изменения в ' +
            (objectIds.length > 1 ? `${objectIds.length} объектах` : '1 объекте');
        } else {
          objectsCount = `Не найдены объекты для изменений`;
        }
        result = error
          ? `При обновлении объектов произшла ошибка. ${error}`
          : objectsCount;
        break;
      }

      default:
        break;
    }
    return target ? `${target} ${result}` : result;
  }
}
