import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';
import {NotificationComponent} from '@core/components/notification/notification.component';

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

@Injectable()
export class NotificationsService {
  private readonly requestTypeAlerts = {
    GET: 'просмотра',
    POST: 'создания',
    PUT: 'изменения',
    DELETE: 'удаления',
  };

  constructor(private $snackBar: MatSnackBar) {}

  public showError(errorCode: number, errorMessage: string, duration = 3000): void {
    this.$snackBar.openFromComponent(NotificationComponent, {
      duration,
      panelClass: ['mat-snack-bar-red', 'mat-snack-bar-custom'],
      data: {
        type: 'error_outline',
        caption: `Код ошибки: ${errorCode}. ${errorMessage}`,
      },
    });
  }

  public showErrorMessage(errorMessage: string, duration = 3000): void {
    this.$snackBar.openFromComponent(NotificationComponent, {
      duration,
      panelClass: ['mat-snack-bar-red', 'mat-snack-bar-custom'],
      data: {
        type: 'error_outline',
        caption: errorMessage,
      },
    });
  }

  public showHttpError(
    response: HttpErrorResponse,
    requestType: RequestType = 'GET',
    dictName?: string,
  ): string {
    let message = '';
    if (response.error instanceof ErrorEvent) {
      // Ошибка на клиенте
      message = 'Произошла ошибка парсинга данных.';
    } else {
      // Ошибка с сервера
      const noWrapDictName = dictName ? ` "${dictName.replace(' ', '&nbsp;')}"` : '';
      switch (response.status) {
        case 401:
          message = 'Произошла ошибка авторизации. Обновите страницу.';
          break;
        case 403:
          message = `Отсутствуют права доступа для ${this.requestTypeAlerts[requestType]} записей в справочнике${noWrapDictName}.`;
          break;
        case 404:
          message = `Не найден метод для ${this.requestTypeAlerts[requestType]} записей в справочнике${noWrapDictName}.`;
          break;
        case 409:
          message = `Произошёл конфликт при создании записи в справочнике${noWrapDictName}.`;
          break;
        case 500:
          message = 'Произошла ошибка парсинга данных на сервере.';
          break;

        default:
          message = 'Произошла непредвиденная ошибка.';
          break;
      }

      this.showErrorMessage(
        message +
          ' Обратитесь в службу технической поддержки <a href="mailto:0911@pik.ru">0911@pik.ru</a>',
        5000,
      );
      return message;
    }
  }

  public showLoader(
    message: string,
    duration?: number,
  ): MatSnackBarRef<NotificationComponent> {
    return this.$snackBar.openFromComponent(NotificationComponent, {
      duration,
      panelClass: ['mat-snack-bar-white', 'mat-snack-bar-custom'],
      data: {
        type: 'error_outline',
        caption: message,
      },
    });
  }

  public showMessage(
    message: string,
    type = 'done',
    duration = 3000,
  ): MatSnackBarRef<NotificationComponent> {
    return this.$snackBar.openFromComponent(NotificationComponent, {
      duration,
      panelClass: ['mat-snack-bar-green', 'mat-snack-bar-custom'],
      data: {
        type,
        caption: message,
      },
    });
  }
}
